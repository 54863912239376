export const getOrderOne = (files = []) => {

    console.log("Empezó aux")
    let result = []

  files.map( (item ) => {

    const divider = item.parents?.split("/")

    if(divider.length > 0) {
        result.push(divider[0])
    } else {
        result.push(item)
    }
    return divider
}
  )

  console.log("Terminó aux", result)

  return result;

}

export const crearEstructuraArbol = (items) => {
    const raiz = {};
  
    items.forEach(item => {
      const rutaPartes = item.parents.split('/'); // Divide la ruta en partes
      let nodoActual = raiz;
  
      rutaPartes.forEach((parte, indice) => {
        // Si no existe la parte en el nodo actual, créala
        if (!nodoActual[parte]) {
          nodoActual[parte] = {};
        }
  
        // Moverse al siguiente nivel del árbol
        nodoActual = nodoActual[parte];
  
        // Si es el último elemento de la ruta, asigna los datos del archivo
        if (indice === rutaPartes.length - 1) {
          nodoActual['_datos'] = item; // '_datos' es un ejemplo, puedes usar cualquier nombre de clave
        }
      });
    });
  
    return raiz;
  };