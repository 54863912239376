//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, Confirm } from "react-st-modal";
//COMPONENTES
import {
  get_debts_detail,
  delete_debts_detail,
} from "../../../services/banck/banck";
//ESTILOS
//IMAGENES/ICONOS
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import SubTableContainer from "../../../main_components/Table/SubTableContainer";
import DELETE from "../../../imgComponents/borrar.png";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { StickyTable } from "react-sticky-table";

const TAX = (props) => {
  const [data, setData] = useState([]);
  const [modalT, setmodalT] = useState(false);

  console.log(props.row.original);
  const handleSubmit = async (event) => {
    event?.preventDefault();
    setmodalT(true);
    try {
      const dt = await get_debts_detail(props.row.original.id);
      console.log(dt);
      setData(dt.data);
      setmodalT(false);
    } catch (err) {
      setData([]);
    }
  };

  const handle_delete_debts_detail = async (id) => {
    try {
      setmodalT(true);
      const dt = await delete_debts_detail(props.row.original.id, id);
      console.log(dt);
      setmodalT(false);

      handleSubmit();
      props.handleSubmit();
    } catch (err) {}
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: "Ac.",
        Cell: ({ row }) => {
          return (
            <div>
              <img
                alt=""
                title="Eliminar pago"
                src={DELETE}
                onClick={async () => {
                  if (
                    sessionStorage.getItem("ROLE_INVOICING") ===
                    "ROLE_INVOICING"
                  ) {
                    const result = await Confirm(
                      "¿Esta usted seguro de eliminar este pago?",
                      "Eliminar Pago",
                      "Si",
                      "No"
                    );
                    if (result) {
                      handle_delete_debts_detail(row.original.id);
                    } else {
                    }
                  } else {
                    alert("No tienes permisos para eliminar un pago");
                  }
                }}
                className="centerText, taxes"
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Fecha de pago",
        accessor: "date",
      },
      {
        Header: "Método de pago",
        accessor: "payment_method",
      },
      {
        Header: "UUID",
        accessor: "uuid",
      },
      {
        Header: "Nota",
        accessor: "note",
      },
      {
        Header: "Total",
        accessor: "total",
      },
      {
        Header: "Numero de cuenta",
        accessor: (d) => {
          return d.last_four_digits !== null
            ? `xxxx-xxxx-xxxx-${d.last_four_digits}`
            : d.last_four_digits;
        },
      },
      {
        Header: "Pagado por",
        accessor: "name_user_of_payment",
      },

    ],
    []
  );

  return (
    <div >
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
          <div className="subtablaexpensesw">
        
          <StickyTable>
            <SubTableContainer
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
            />
          </StickyTable>
        </div>
    </div>
  );
};

export default TAX;
