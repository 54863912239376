import React, { useEffect, useState } from 'react'
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { get_cabinets_warehouse, get_status_cabinets, post_cabinets_movement } from '../../../services/assets/assets';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDialog } from 'react-st-modal';

export const MOVAGROUP = (props) => {
    
    const [datawarehouse, setdatawarehouse]=useState([]);
    const [datastatus, setdatastatus]=useState([]);
    const [datastatusf, setdatastatusf]=useState([]);

    const [bandera, setbandera]=useState(false);
    const [note, setnote]=useState("");
    const [id_warehouse, setid_warehouse]=useState(props?.detalles[0]?.id_warehouse_fk);
    const [id_cabinets_state, setid_cabinets_state]=useState(props?.detalles[0]?.id_cabinets_state_fk);


    const [cong, setcong]=useState(props?.detalles);

    const dialog = useDialog();

    useEffect(() => {
        handlewarehouse();
        handlestatus();
    }, [])

    useEffect(() => { 
        analisis(datastatusf);
    }, [id_warehouse])
    
    const analisis = (dt)=>{
        if(id_warehouse === "8" || id_warehouse === 8){
            var d = dt.filter(item=>item.id===3);
            setdatastatus(d);
        }else if(id_warehouse === "9" || id_warehouse === 9){
            var d = dt.filter(item=>item.id===1);
            setdatastatus(d);
        }else if(id_warehouse === "11" || id_warehouse === 11){
            var d = dt.filter(item=>item.id===5);
            var e = dt.filter(item=>item.id==12);
            const array = [...d, ...e];
            setdatastatus(array);
        }else if(id_warehouse === "12" || id_warehouse === 12){
            var d = dt.filter(item=>item.id===16);
            setdatastatus(d);
        }else if(id_warehouse === "13" || id_warehouse===13){
            var d = dt.filter(item=>item.id===11);
            setdatastatus(d);
        }else if(id_warehouse === "16" || id_warehouse === 16){
            var d = dt.filter(item=>item.id===3);
            setdatastatus(d);
        }else if(id_warehouse === "17" || id_warehouse === 17){
            setdatastatus([]);
        }else if(id_warehouse === "46" || id_warehouse === 46){
            var d = dt.filter(item=>item.id===1)
            setdatastatus(d);
        } 
    }



    const handlewarehouse = async () => {
        try {  
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try{
                var d = await get_cabinets_warehouse();
                setdatawarehouse(d === null ? [] : d.data.warehouses);
              }catch(err){
                setdatawarehouse([]);
              }
              
            }, 1000);
          }else{}
        } catch (error) {
          console.error(error);
          
        } 
    }
  
    const handlestatus = async () => {
        try {  
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try{
                var d = await get_status_cabinets(); 
                setdatastatusf( d === null ? [] : d?.data?.cabinet_status);
                analisis(d?.data?.cabinet_status);
              }catch(err){
                setdatastatus([]);
                setdatastatusf([]);
              }
            }, 1000);
          }else{}
        } catch (error) {
          console.error(error);
        }
    }

    const createmovement = async() =>{

        if(note==="" || note?.trim()==="" || note===null){
            props.alertas("Debe asignar nota para realizar el movimiento", false);
            return null;
        }

        try{
            props.reconsultar(true);
            setbandera(true);
            
            for (const item of cong) {
                await postmov(item);
            }    
            
            props.setselectedmov([]);
            props.alertas("Movimientos realizados con éxito", true);
            props.reconsultar(false);
            dialog.close();
            props.handleSubmit();

        }catch(err){
            console.log(err);
        }    
    }
    
    
    const postmov = async(item) =>{

        var data = {
            "movement":{
                "id_warehouse": parseInt(id_warehouse),
                "id_point_of_sale": null,
                "id_employee": null
              },
              "note": note,
              "status": parseInt(id_cabinets_state)
        }

        try{
            const rf_token = await refresh_token();
            if(rf_token===true){
              return new Promise((resolve, reject) => {
                setTimeout(async() => {
                    try{
                        const d = await post_cabinets_movement(item?.id, data);
                        resolve(d);
                    }catch(err){   
                        console.log(err);
                        props.reconsultar(false);
                        props.alertas("No se pudo realizar el movimieto de los congeladores", false);
                        setbandera(false);
                        reject(err);
                    }
                }, 1500);
              })
            }
        }catch(err){
            console.log(err);
            return Promise.reject(err);
        }
    }

  return (
    <div>  
        <div className='grid-mov-almacen-agroup'>
        <div className="mov-almac-1-agroup">
          <TextField
                  select
                  label={"almacen"}
                  disabled={bandera}
                  value={id_warehouse}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(e)=>setid_warehouse(e.target.value)}  
                 >
                {
                    datawarehouse.map(item=>(
                        <option key={item.id} value={item.id}>
                        {item.warehouse_name}
                        </option>
                    ))
                }
                </TextField>
          </div>
            <div className="mov-almac-2-agroup">
                <TextField
                  select
                  label="Estado Congelador"
                  disabled={bandera}
                  defaultValue={""}
                  value={id_cabinets_state}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_cabinets_state(event.target.value)}  
                 >
                    <option key={"0"} value={"0"}>
                        {"Selecciona Estado"}
                    </option>
                {
                  datastatus.map(item=>(
                    <option key={item.id} value={item.id}>
                    {item.description}
                    </option>
                  ))
                }
                </TextField>
          </div>
        </div>
        <div className='grid-mov-almacen-agroup-2'>
            <div className="mov-almac-3-agroup">
                <TextField
                label="Nota"
                disabled={bandera}
                sx={{ m: 1.5, width: "100%" }}
                value={note}
                onChange={(event) => setnote(event.target.value)}  
                InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
        </div>
        <dic className="text-desc-mov-agroup">
            <p>Congeladores Seleccionados: {props?.detalles?.length}</p>
        </dic>

        <Box>      
          <Button
            variant="contained"
            style={{"background-color":"green", "color":"white"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={()=>createmovement()}
          >
            GUARDAR
          </Button>
            <Button
              style={{"background-color":"red", "color":"white"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
    </div>
  )
}
