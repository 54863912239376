/* eslint-disable jsx-a11y/alt-text */
//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog } from "react-st-modal";
import NotificationSystem from "react-notification-system";
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import Button from "@mui/material/Button";

import { get_price_load } from "../../services/inventory/inventory";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import TextField from "@mui/material/TextField";
import { get_routes } from "../../services/cashclosing/cashclosing";
import { COLUMS_SHOW } from "../../main_components/Table/ColumsShow";
import Edit from "../../imgComponents/lupa.png";
import { get_route_list } from "../../services/businesspartners/businesspartners";
import DETAIL_SALE from "./detail/detail";
import {
  GENERATE_COLUMNS,
  SHOWDATA,
} from "../../main_components/Table/generate_columns";
import { MaterialUISwitch } from "../../main_components/material_switch/switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import RE from "../../imgComponents/actualizar.png";

//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../componentSociosNegocios/componentSectors/bonuses.css";
import "../../components_cfdi/modal.css";
import "./product_load.css";
import { SelectColumnFilter } from "../../components_cfdi/filters";
//IMAGENES/ICONOS

const PRODUCT_LOAD = () => {
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data_original, setdata_original] = useState([]);

  const [sales_data, set_sales_data] = useState([]);
  const [sales_returns, set_sales_returns] = useState([]);

  const [sales_data_original, set_sales_data_original] = useState([]);
  const [sales_returns_original, set_sales_returns_original] = useState([]);

  const [open, setopen] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [fi, setfi] = useState("");
  const [ruta, setruta] = useState("vacio");
  const [routedata, setroutedata] = useState([]);
  const [ff, setff] = useState(true);
  const [ffi, setffi] = useState(true);
  const [routes, setroutes] = useState([]);
  const [selectRoute, setselectRoute] = useState("Selecciona una ruta");
  const [vendor, setvendor] = useState([]);
  const [selectVendro, setselectVendro] = useState("Selecciona un usuario");
  const [msj_data, setmsjdata] = useState("");
  const [checked_data, setchecked_data] = useState(true);
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const showcolumns = GENERATE_COLUMNS("product_load", 7);
  const [code_product, set_code_product] = useState("");

  const handle_router_select = (event) => {
    setselectRoute(event?.target?.value);
    const arryVendor = routes
      ?.filter((route) => route.route === event?.target?.value)
      .map((user) => user.users);
  
    const isSelectVendorPresent = arryVendor[0].some(
      (vendor) => vendor.fullusername === "Selecciona un usuario"
    );
  
    if (!isSelectVendorPresent) {
      var dtAdd = {
        id: "Selecciona un usuario",
        id_warehouse_fk: "Selecciona un usuario",
        fullusername: "Selecciona un usuario",
      };
  
      arryVendor[0].push(dtAdd);
    }
  
    setvendor([]);
    setvendor(arryVendor);
    setselectVendro("Selecciona un usuario");
  };

  const handle_type_vendor = (event) => {
    setselectVendro(event?.target?.value);
  };

  const handle_router = async () => {
    try {
      const dt = await get_routes();
      const data = dt.data;
      var dtAdd = {
        route: "Selecciona una ruta",
        users: [
          {
            id: "Selecciona una ruta",
            fullusername: "Selecciona antes una ruta",
          },
        ],
      };
      data.push(dtAdd);
      console.log(data);
      setroutes(data);
    } catch (error) {
      setroutes([]);
    }
  };

  useEffect(() => {
    handle_router();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setvendor(vendor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleroute();
  }, []);

  function alertas(msj, status) {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  }
  const reconsultar = (n) => {
    if (n === true) {
      setmodalT(true);
    } else {
      setmodalT(false);
    }
  };
  const handleSubmit = async () => {
    setmodalT(true);
    setopen(true);
    setData([]);
    set_sales_data([]);
    set_sales_returns([]);
    set_code_product("");

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_price_load(ffi, ff, selectRoute, selectVendro);
            console.log(d.data?.message);
            setmsjdata(d.data?.message);
            set_sales_data(d === null ? [] : d.data?.sales);
            set_sales_data_original(d === null ? [] : d.data?.sales);
            set_sales_returns(d === null ? [] : d.data.returns);
            set_sales_returns_original(d === null ? [] : d.data.returns);
            const productDictionary = {};
            d.data?.sales_products?.forEach((product) => {
              productDictionary[product.code] = {
                ...productDictionary[product.code],
                sales_products: product?.quantity,
                name: product?.product_name,
              };
            });

            d.data?.returns_products?.forEach((product) => {
              console.log(product?.quantity);

              productDictionary[product.code] = {
                ...productDictionary[product.code],
                returns_products: product?.quantity,
                name: product?.product_name,
              };
            });
            const inventory = d.data?.inventory?.filter(
              (d) => d.code !== "1155585588"
            );
            inventory?.forEach((product) => {
              productDictionary[product.code] = {
                ...productDictionary[product.code],
                inventory_products: product?.quantity,
                name: product?.product_name,
              };
            });

            const join_data = {
              join_data: Object.keys(productDictionary)
                .map((code) => ({
                  code,
                  ...productDictionary[code],
                }))
                .sort((a, b) => a.code.localeCompare(b.code)),
            };

            const dta = join_data.join_data.slice().sort((a, b) => {
              const aSales =
                a.sales_products !== undefined
                  ? a.sales_products
                  : Number.POSITIVE_INFINITY;
              const bSales =
                b.sales_products !== undefined
                  ? b.sales_products
                  : Number.POSITIVE_INFINITY;
              return aSales - bSales;
            });

            setData(dta);
            setData2(dta.filter((d) => d.sales_products !== undefined));
            setdata_original(dta);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      } else {
        setopen(false);
      }
    } catch (err) {
      console.log(err);
      setopen(false);

    }
  };

  const handleroute = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_route_list();
            setroutedata(d === null ? [] : d);
          } catch (err) {
            console.log(err);
            alertas("Error al obtener información de rutas", false);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filter_Sales = (c) => {
    const ventasConDetalleFiltrado = [];
    const returnConDetalleFiltrado = [];

    for (const venta of sales_data_original) {
      const detallesVenta = venta.details;
      const detalleFiltrado = detallesVenta.find(
        (detalle) => detalle.code === c
      );

      if (detalleFiltrado) {
        ventasConDetalleFiltrado.push(venta);
      }
    }

    if (ventasConDetalleFiltrado.length > 0) {
      const ventasConDetalleAgrupado = {
        productoCodigo: c,
        ventas: [],
      };

      for (const ventaFiltrada of ventasConDetalleFiltrado) {
        ventasConDetalleAgrupado.ventas.push(ventaFiltrada);
      }

      console.log("Ventas con el producto de código", c);
      console.log(ventasConDetalleAgrupado);

      set_sales_data(ventasConDetalleAgrupado?.ventas);
      set_code_product(ventasConDetalleAgrupado?.productoCodigo);
    } else {
      console.log(`No se encontraron ventas con el producto de código ${c}`);
      set_sales_returns([]);

    }

    for (const venta of sales_returns_original) {
      const detallesVenta = venta.details;
      const detalleFiltrado = detallesVenta.find(
        (detalle) => detalle.code === c
      );

      if (detalleFiltrado) {
        returnConDetalleFiltrado.push(venta);
      }
    }

    if (returnConDetalleFiltrado.length > 0) {
      const retunsConDetalleAgrupado = {
        productoCodigo: c,
        return: [],
      };

      for (const ventaFiltrada of returnConDetalleFiltrado) {
        retunsConDetalleAgrupado.return.push(ventaFiltrada);
      }

      console.log("returns con el producto de código", c);
      console.log(retunsConDetalleAgrupado);

      set_sales_returns(retunsConDetalleAgrupado?.return);

    } else {
      console.log(
        `No se encontraron los retuns con el producto de código ${c}`
      );
      set_sales_returns([]);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: (d) => {
          return d.code;
        },
        Cell: ({ row }) => {
          return (
            <p
              onClick={() => filter_Sales(row.original.code)}
              style={{
                background:
                  row.original?.code === code_product ? "#00ff4c" : "",
                cursor: "pointer",
              }}
            >
              {row.original.code}
            </p>
          );
        },
        show: showcolumns.product_load_c1?.value,
        id: "code",
      },
      {
        Header: "Producto",
        accessor: "name",
        show: showcolumns.product_load_c2?.value,
        id: "name",
        className: "lef_data",
      },
      {
        Header: "Vendido",
        accessor: (d) => {
          return d.sales_products === undefined ? 0 : d.sales_products;
        },
        show: showcolumns.product_load_c3?.value,
        id: "sales_products",
      },
      {
        Header: "Devolución",
        accessor: (d) => {
          return d.returns_products === undefined ? 0 : d.returns_products;
        },
        show: showcolumns.product_load_c4?.value,
        id: "returns_products",
      },
      {
        Header: "Venta Neta",
        accessor: (d) => {
          return (
            (isNaN(Number(d?.sales_products)) === true
              ? 0
              : Number(d?.sales_products)) -
            (isNaN(Number(d?.returns_products)) === true
              ? 0
              : Number(d?.returns_products))
          );
        },
        show: showcolumns.product_load_c5?.value,
        id: "total_returns_products",
      },
      {
        Header: "Inv. Final",
       
        show: showcolumns.product_load_c6?.value,
        id: "inventory_products",
        accessor: (d) => {
          return d.inventory_products === undefined ? "Sin carga" : d.inventory_products;
        },
        Cell: ({ row }) => {
          return row.original?.inventory_products === undefined ? (
            <p style={{ color: "red" }}>Sin Carga</p>
          ) : (
            row.original?.inventory_products
          );
        },
      },
      {
        Header: "Entrega",
        accessor: (d) => {
          return (
            (isNaN(Number(d?.sales_products)) === true
              ? 0
              : Number(d?.sales_products)) -
            (isNaN(Number(d?.returns_products)) === true
              ? 0
              : Number(d?.returns_products)) -
            (isNaN(Number(d?.inventory_products)) === true
              ? 0
              : Number(d?.inventory_products))
          );
        },
        show: showcolumns.product_load_c7?.value,
        id: "total_products",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showcolumns, data, data_original, code_product, sales_data, sales_returns]
  );

  const columns2 = useMemo(
    () => [
      {
        Header: "Acciones",
        id: "Acciones",
        Cell: ({ row }) => {
          return (
            <div>
              <img
                src={Edit}
                onClick={async () => {
                  CustomDialog(
                    <DETAIL_SALE
                      sale_retun={"Venta"}
                      ticket={row.original.ticket}
                      name_pos={row.original.name_pos}
                      subtotal={row.original.subtotal}
                      discount={row.original.discount}
                      tax={row.original.tax}
                      total={row.original.total}
                      debt={row.original.debt}
                      payed={row.original.payed}
                      payment_method={row.original.payment_method}
                      scan={row.original.scan}
                      note={row.original.note}
                      name_business_partner={row.original.name_business_partner}
                      rfc_business_partner={row.original.rfc_business_partner}
                      code_pos={row.original.code_pos}
                      details={row.original.details}
                      lng={row.original.longitude}
                      lat={row.original.latitude}
                    ></DETAIL_SALE>,
                    {
                      className: "modalTItle",
                      title: "Detalles",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
                className="img-product"
                title="Ver detalles"
              ></img>
            </div>
          );
        },
        className: "center_data",
        maxWidth: 80,
        minWidth: 80,
        width: 80,
      },
      {
        Header: "Fecha",
        accessor: "date",
        className: "center_data",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
      },
      {
        Header: "Ticket",
        accessor: "ticket",
        className: "center_data",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
      },
      {
        Header: "Cliente",
        accessor: "name_business_partner",
        className: "justify_data",
        maxWidth: 130,
        minWidth: 130,
        width: 130,
      },
      {
        Header: "Punto de venta",
        accessor: "name_pos",
        className: "justify_data",
        maxWidth: 130,
        minWidth: 130,
        width: 130,
      },

      {
        Header: "Tipo de venta",
        accessor: "saletype",
        Filter: SelectColumnFilter,
        filter: "equals",
        className: "center_data",
      },
      {
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
        className: "right_data",
      },
      {
        Header: "Usuario",
        accessor: "name_person_user",
        className: "justify_data",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, data_original, code_product, sales_data, sales_returns]
  );

  const columns3 = useMemo(
    () => [
      {
        Header: "Acciones",
        id: "Acciones",
        Cell: ({ row }) => {
          return (
            <div>
              <img
                src={Edit}
                onClick={async () => {
                  CustomDialog(
                    <DETAIL_SALE
                      sale_retun={"Devolución"}
                      ticket={row.original.ticket}
                      name_pos={row.original.name_pos}
                      subtotal={row.original.subtotal}
                      discount={row.original.discount}
                      tax={row.original.tax}
                      total={row.original.total}
                      debt={row.original.debt}
                      payed={row.original.payed}
                      payment_method={row.original.payment_method}
                      scan={row.original.scan}
                      note={row.original.note}
                      name_business_partner={row.original.name_business_partner}
                      rfc_business_partner={row.original.rfc_business_partner}
                      code_pos={row.original.code_pos}
                      details={row.original.details}
                      test={row.original}
                      lng={row.original.longitude}
                      lat={row.original.latitude}
                    ></DETAIL_SALE>,
                    {
                      className: "modalTItle",
                      title: "Detalles",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
                className="img-product"
                title="Ver detalles"
              ></img>
            </div>
          );
        },
        className: "center_data",
        maxWidth: 80,
        minWidth: 80,
        width: 80,
      },
      {
        Header: "Fecha",
        accessor: "date",
        className: "center_data",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
      },
      {
        Header: "Ticket",
        accessor: "ticket",
        className: "center_data",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
      },
      {
        Header: "Tipo",
        accessor: "type",
        className: "center_data",
      },

      {
        Header: "Cliente",
        accessor: "name_business_partner",
        className: "justify_data",
        maxWidth: 130,
        minWidth: 130,
        width: 130,
      },
      {
        Header: "Punto de venta",
        accessor: "name_pos",
        className: "justify_data",
        maxWidth: 130,
        minWidth: 130,
        width: 130,
      },

      
      {
        Header: "Tipo de venta",
        accessor: "saletype",
        Filter: SelectColumnFilter,
        filter: "equals",
        className: "center_data",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
      },
      {
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
        className: "right_data",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
      },
      {
        Header: "Usuario",
        accessor: "name_person_user",
        className: "center_data",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, data_original, code_product, sales_data, sales_returns]
  );

  return (
    <>
      <BREADCRUMBS
        niveles={[{ label: "CONSULTAR CARGA DE PRODUCTOS", path: null }]}
      ></BREADCRUMBS>
      <div >
        <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
        <MODAL_TABLE
          open={true}
          message={menssage}
          modalGeneral={modalGeneral}
          modalT={modalT}
        ></MODAL_TABLE>
        <COLUMS_SHOW
          filtersColumsView={filtersColumsView}
          datacolums={SHOWDATA(columns, showcolumns, "product_load")}
        />

        <div className="price-load-dta">
          <div className="expen-5n">
            <input
              type="text"
              className="fExpense2"
              onClick={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha Inicial"
              onChange={(event) => setffi(event.target.value)}
            ></input>
          </div>

          <div className="expen-6">
            <input
              type="text"
              className="fExpense2"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha Final"
              onChange={(event) => setff(event.target.value)}
            ></input>
          </div>
          <div className="expen-7">
            <TextField
              select
              label="Ruta"
              child
              value={selectRoute}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handle_router_select}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {routes?.map((option) => (
                <option
                  key={option?.route}
                  value={option?.route}
                  selected={option?.route}
                >
                  {option?.route}
                </option>
              ))}
            </TextField>
          </div>

          <div className="expen-8">
            <TextField
              select
              child
              label="Usuario"
              value={selectVendro}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handle_type_vendor}
              size="small"
              disabled={selectRoute==="Selecciona una ruta"?true:false}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {vendor[0]?.map((option) => (
                <option key={option.id} value={option.id} selected={option.id}>
                  {option.fullusername}
                </option>
              ))}
            </TextField>
          </div>

          <div className="expen-9">
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "100%" }}
              className="ButtonModal"
              onClick={()=>handleSubmit()}
              disabled={selectVendro==="Selecciona un usuario"?true:false}
            >
              CONSULTAR
            </Button>
          </div>
          <div className="expen-10">
            <FormControlLabel
              control={
                <MaterialUISwitch
                  sx={{ m: 1 }}
                  onChange={(e) => {
                    setchecked_data(e.target.checked);
                    setData(checked_data === true ? data2 : data_original);
                  }}
                  checked={checked_data}
                />
              }
              label={checked_data === true ? "VENTAS" : "TODOS"}
            />
          </div>

          <div className="expen-11">
            <FormControlLabel
              control={
                <MaterialUISwitch
                  sx={{ m: 1 }}
                  onChange={(e) => setfiltersColumsView(e.target.checked)}
                  checked={filtersColumsView}
                />
              }
              label={
                filtersColumsView === true
                  ? "Activar Filtros"
                  : "Desactivar Filtros"
              }
            />
          </div>
        </div>

        <div className="todoaaaa">
          <span class="closebtn" onClick={() => setmsjdata("")}>
            &times;
          </span>
          <div
            className="alertm"
            style={{
              display: msj_data?.length === 0 ? "none" : "inline-block",
            }}
          >
            {msj_data}
          </div>
        </div>

        <>
          <div className="reconsulta">
            <img src={RE} onClick={() => handleSubmit()} title="RE-CONSULTAR" />
          </div>
          <div className="TableProducLoad">
            <StickyTable>
              <TableContainer
                paginado={"Productos"}
                consultas={"Productos Consultados"}
                nametable={"Tabla Productos"}
                GrantotalCabeceras={["Código", ""]}
                title={"Ruta: "+selectRoute}
                Grantotal={true}
                exportar={true}
                columns={columns}
                hpdf={0}
                data={data}
                style={{ overflowx: 10 }}
                Gmodal={open}
                v={true}
              />
            </StickyTable>
          </div>
          <br></br>
          <div className="TableProducLoad">
            <StickyTable>
              <TableContainer
                paginado={"Ventas"}
                consultas={"Ventas Consultadas"}
                nametable={"Tabla Ventas"}
                Grantotal={true}
                hpdf={1}
                exportar={true}
                columns={columns2}
                data={sales_data}
                style={{ overflowx: 10 }}
                Gmodal={open}
              />
            </StickyTable>
          </div>
          <br></br>
          <br></br>
          <div className="TableProducLoad">
            <StickyTable>
              <TableContainer
                paginado={"Devoluciones"}
                consultas={"Devoluciones Consultadas"}
                nametable={"Tabla Devoluciones"}
                Grantotal={true}
                exportar={true}
                hpdf={1}
                columns={columns3}
                data={sales_returns}
                style={{ overflowx: 10 }}
                Gmodal={open}
              />
            </StickyTable>
          </div>
        </>
      </div>
    </>
  );
};

export default PRODUCT_LOAD;
