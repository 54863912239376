//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog } from "react-st-modal";
import LoadingScreen from "react-loading-screen";
//COMPONENTES
import SetBonuses from "./SetBonuses";
//ESTILOS
import "./componentVentasBonificaciones/estilostable.css";
import { inflate } from 'pako';
//IMAGENES/ICONOS
import Gsheets from "../imgComponents/gsheets.png"
import Spinner from "../imgComponents/S.gif";
import Edit from "../imgComponents/save.png";
import ADD from "../imgComponents/agregar.png"
import Package from "../imgComponents/package.png"
import { refresh_token } from "../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../main_components/pagination/breadcrumbs";
import "./bonificaciones.css";
import { get_bonification_packages, get_bonification_packages_report, post_bonification_packages_report } from "../services/bonifications/bonifications";
import { get_bonus } from "../services/unilever/unilever";
import DMS from "../imgComponents/reportSales.png"
import TableContainer from "../main_components/Table/TableContainer";

var JSON_object;
var params = {
  sheets_number: 0,
  columns: [],
  rows: 0,
  sheetName: [],
  headers: [],
  title: '',
  includeRowTitle: false,
  includeRowHeader: false,
  colorHeader: {
    red: 0,
    green: 0,
    blue: 0
  },
  colorTitles: {
    red: 0,
    green: 0,
    blue: 0
  }
}


const TableBonificaciones = () => {
  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();
  var añoantes = date.getFullYear();
  var mesantes = Number(mes) - 1;

  if (mesantes === 0) {
    mesantes = `${12}`.padStart(2, "0");
    añoantes = añoantes - 1;
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }




  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${añoantes}-${mesantes}-${dia}`;

  sessionStorage.setItem("idpack", 0);
  sessionStorage.setItem("name_pack", "");
  sessionStorage.setItem("num_pack", 0);
  sessionStorage.setItem("id_pack_edit", 0);
  sessionStorage.setItem("id_pack_edit", 0);
  sessionStorage.setItem("name_pack_edit", "");
  sessionStorage.setItem("num_pack_edit", 0);

  const [data, setData] = useState([]);
  const fecha = new Date();
  const [fi, setfi] = useState(recuperarPPDantes);
  const [ff, setff] = useState(recuperarPPD);
  const [isLoading, setIsLoading] = useState(false);
  const [MensajeBusqueda, setMensajeBusqueda] = React.useState("");

  //proceso de consultar paquete de bonificaciones
  const handleSubmit = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_bonification_packages(fi, ff);
            setData(d === null ? [] : d.data.bonificationPackageList);
          } catch (err) {
            console.log("error", err);
            setData([]);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);


  const crear = async (id) => {
    setIsLoading(true);
    setMensajeBusqueda("Creando Reporte")
    var dato = {
      bonificacion: 'bonificaciones',
    }
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await post_bonification_packages_report(id, dato);
            console.log("RESPONSE :", d);
            if (d?.status === 202) {
              setIsLoading(false);
              alert("El paquete de bonificación será procesado.\nSe le notificará a su correo cuando pueda consultarlo.\n\nConsidere que para un paquete de 200 ventas el tiempo de espera es de 1 minuto aproximadamente.");
            }
          } catch (err) {
            setIsLoading(false);
            console.log("AXIOS ERROR: ", err);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }
  const consultar = async () => {
    setIsLoading(true);
    setMensajeBusqueda("Generando Reporte en Google Sheets");
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_bonification_packages_report(pqbo);
            if (d?.status === 200) {
              console.log("si hay paquete de bonificaciones");
              console.log(d?.data?.data);
              const buffer = base64ToArrayBuffer(d?.data?.data);
              const uncompressedData = inflate(buffer, { to: 'string' });
              const uncompressedJson = JSON.parse(uncompressedData);
              console.log(uncompressedJson);
              loadJSON(uncompressedJson, d);
            }
            if (d?.status === 205) {
              alert("El paquete de bonificación será procesado.\nSe le notificará a su correo cuando pueda consultarlo.\n\nConsidere que para un paquete de 200 ventas el tiempo de espera es de 1 minuto aproximadamente.");
              setIsLoading(false);
            }
          } catch (err) {
            console.log("AXIOS ERROR: ", err);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }


  //creacion de google sheets
  var SHEET_ID = '';
  var CLIENT_ID = '567449702519-pblcrf4t9rgpv6kmr8r6210c2pg4qlq2.apps.googleusercontent.com';
  var API_KEY = 'AIzaSyCcrrKXSsnKW1G8gt5_E5tgXSKofFXUHhA';
  var DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];
  var SCOPES = "https://www.googleapis.com/auth/spreadsheets";
  var pqbo;

  useEffect(() => {
    initTokenClient();
  }, [])

  var tokenClient;
  var access_token;

  function getToken() {
    console.log("gettoken()")
    tokenClient.requestAccessToken();
  }
  function initTokenClient() {

    const Google = window.google;

    tokenClient = Google.accounts.oauth2.initTokenClient({
      client_id: "567449702519-pblcrf4t9rgpv6kmr8r6210c2pg4qlq2.apps.googleusercontent.com",
      scope: 'https://www.googleapis.com/auth/spreadsheets',
      discoveryDocs: DISCOVERY_DOCS,
      prompt: 'consent',
      callback: (tokenResponse) => {
        access_token = tokenResponse.access_token;
        //console.log(access_token)
        consultar();
      },
    });

    console.log(tokenClient);
  }

  //inicio de sesion anterior

  function handleClientLoad(pk) {
    pqbo = pk;
    console.log("metodo handleClientLoad " + pqbo)
    window.gapi.load('client:auth2', initClient);
  }
  function appendPre(message) {
    console.log("metodo appenPre");
    var pre = document.getElementById('content');
    var textContent = document.createTextNode(message + '\n');
    pre.appendChild(textContent);
  }
  function initClient() {
    console.log("metodo initCLiente");

    window.gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      //scope: SCOPES,
      //prompt: 'consent'
    }).then(function () {
      getToken();

    }, function (error) {
      console.log("error grave")
      appendPre(JSON.stringify(error, null, 2));
    });
  }


  /*Objetos del generador*/
  function getName() {
    var date = new Date();
    window.packageName = JSON_object[0][0].fileName;
    return window.packageName + "_" + date.getYear() + date.getMonth() + date.getDay() + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
  }
  function createSheet() {
    window.gapi.client.sheets.spreadsheets.create(
      {
        properties: {
          title: getName()
        }
      }).then((response) => {
        SHEET_ID = response.result.spreadsheetId;
        console.log("Hoja creada\nID: " + SHEET_ID);

        setTimeout(() => {
          setIsLoading(false);
          alert("Se generó correctamente el reporte");
        }, 1000);

        addFormat();
      })
  }
  function addFormat() {
    var requests = getRequests()
    console.log(requests);
    window.gapi.client.sheets.spreadsheets.batchUpdate({
      spreadsheetId: SHEET_ID
    }, {
      'requests': requests
    }).then(() => {
      fillRows()
    })
  }
  function getHeaders() {
    var aux = []
    //headers de cada hoja
    for (var h = 0; h < JSON_object[0].length; h++) {

      var headers = new Array()
      for (var rt = 0; rt < JSON_object[0][h].headers.length; rt++) {
        headers.push(JSON_object[0][h].headers[rt].headerText)
      }

      aux.push({
        "range": removeUnderScore(JSON_object[0][h].sheetName) + '!A2',
        "values": [headers]
      })

      aux.push(
        {
          "range": removeUnderScore(JSON_object[0][h].sheetName) + '!A1',
          "values": [[JSON_object[0][h].title]]
        }
      )
    }
    return aux;
  }
  function setHeaders() {
    var data = getHeaders()
    window.gapi.client.sheets.spreadsheets.values.batchUpdate({
      spreadsheetId: SHEET_ID
    }, {
      valueInputOption: "USER_ENTERED",
      data: data
    }).then(() => {
      console.log("Atributos de la Sheet generados");
      window.open('https://docs.google.com/spreadsheets/d/' + SHEET_ID + '/', '_blank');

    })
  }
  function removeUnderScore(text) {
    var aux = ''
    for (var s = 0; s < text.length; s++) {
      if (!text.charAt(s).match('_')) {
        aux += text.charAt(s)
      }
      else {
        aux += ' '
      }
    }
    return aux
  }
  function getRequests() {
    var aux = [];
    /*Crear hojas*/
    aux.push({
      "updateSheetProperties": {
        "properties": {
          "sheetId": 0,
          "title": removeUnderScore(JSON_object[0][0].sheetName)
        },
        "fields": "title"
      }
    })
    if (params.sheets_number > 1) {
      for (var h = 1; h < JSON_object[0].length; h++) {
        aux.push(
          {
            "addSheet": {
              "properties": {
                "sheetId": h,
                "title": removeUnderScore(JSON_object[0][h].sheetName)
              }
            }
          })
      }
    }
    else { }
    /* Pintar Headers */
    var setDataIndex = 0;
    if (params.includeRowHeader) {
      setDataIndex++;
      for (var h = 0; h < JSON_object[0].length; h++) {
        aux.push({
          "mergeCells": {
            "range": {
              "sheetId": h,
              "startRowIndex": 0,
              "endRowIndex": 1,
              "startColumnIndex": 0,
              "endColumnIndex": JSON_object[0][h].headers.length
            },
            "mergeType": "MERGE_ALL"
          }
        }, {
          "repeatCell": {
            "range": {
              "sheetId": h,
              "startRowIndex": 0,
              "endRowIndex": 1,
              "startColumnIndex": 0,
              "endColumnIndex": JSON_object[0][h].headers.length
            },
            "cell": {
              "userEnteredFormat": {
                "backgroundColor": {
                  "red": 0.0,
                  "green": 0.0,
                  "blue": 1
                },
                "horizontalAlignment": "CENTER",
                "textFormat": {
                  "foregroundColor": {
                    "red": 1.0,
                    "green": 1.0,
                    "blue": 1.0
                  },

                  "bold": true
                }
              }
            },
            "fields": "userEnteredFormat(backgroundColor,textFormat,horizontalAlignment)"
          }
        });

      }
    }
    if (params.includeRowTitle) {
      setDataIndex++;
      for (var h = 0; h < JSON_object[0].length; h++) {
        aux.push({
          "repeatCell": {
            "range": {
              "sheetId": h,
              "startRowIndex": 1,
              "endRowIndex": 2,
              "startColumnIndex": 0,
              "endColumnIndex": JSON_object[0][h].headers.length
            },
            "cell": {
              "userEnteredFormat": {
                "backgroundColor": {
                  "red": 217.00 / 255,
                  "green": 217.00 / 255,
                  "blue": 217.00 / 255
                },
                "horizontalAlignment": "CENTER",
                "textFormat": {
                  "foregroundColor": {
                    "red": 0.0,
                    "green": 0.0,
                    "blue": 0.0
                  },
                  "bold": true
                }
              }
            },
            "fields": "userEnteredFormat(backgroundColor,textFormat,horizontalAlignment)"
          }
        });
      }
    }
    /*Crear bordes*/
    for (var h = 0; h < JSON_object[0].length; h++) {
      aux.push({
        "updateBorders": {
          "range": {
            "sheetId": h,
            "startRowIndex": 0,
            "endRowIndex": JSON_object[h + 1].length + 2,
            "startColumnIndex": 0,
            "endColumnIndex": JSON_object[0][h].headers.length
          },
          "top": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          },
          "bottom": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          },
          "left": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          },
          "right": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          },
          "innerHorizontal": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          },
          "innerVertical": {
            "style": "SOLID",
            "color": { "red": 0, "green": 0, "blue": 0 }
          }
        }
      });
    }
    console.warn(aux);
    return aux;
  }
  function drawData(hojas) {
    for (var h = 0; h < JSON_object[0].length; h++) {
      window.gapi.client.sheets.spreadsheets.values.append({
        spreadsheetId: SHEET_ID,
        range: removeUnderScore(JSON_object[0][h].sheetName) + '!A3',
        valueInputOption: 'USER_ENTERED',
        resource: {
          values:
            hojas[h]
        }
      }).then(() => {
        console.log("Hoja cargada");
      });
    }
    setHeaders();
  }
  function fillRows() {
    var hojas = new Array()
    for (var h = 1; h < params.sheets_number; h++) {
      var aux2 = new Array();
      for (var r = 0; r < JSON_object[h].length; r++) {
        var aux = new Array;
        for (var rt = 0; rt < JSON_object[0][h - 1].headers.length; rt++) {
          var tableHeaders = JSON_object[0][h - 1].headers;
          aux.push(JSON_object[h][r][tableHeaders[rt].headerJs]);
        }
        aux2.push(aux);
      }
      hojas.push(aux2);
    }
    drawData(hojas);
  }

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }



  function loadJSON(json, res) {

    if (res.status === 200) {

      JSON_object = json;

      console.log(JSON_object);

      JSON_object[0][3].headers[12].headerJs = "quantity";

      params = {
        sheets_number: JSON_object.length,
        columns: JSON_object[0][0].columns,
        rows: JSON_object[0][0].rows,
        sheetName: JSON_object[0][0].sheetName,
        headers: JSON_object[0][0].headers,
        title: JSON_object[0][0].title,
        includeRowTitle: JSON_object[0][0].includeRowTitle,
        includeRowHeader: JSON_object[0][0].includeRowHeader
      }

      console.log(JSON_object);
      createSheet();
    } else {
      console.log("error");
    }
  }


  const handle_bonus = async (id) => {
 
    setIsLoading(true);
    setMensajeBusqueda("Generando interfaz...")

    try{
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            console.log(id)
            await get_bonus(id);
            setIsLoading(false);
            setMensajeBusqueda("")
            alert("Éxito: Paquete generado correctamente.")
          } catch (err) {
            setIsLoading(false);
            alert( JSON.stringify(err.response.data))
            setMensajeBusqueda("")

          }   
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {

          function CrearPaquete() {
            crear(row.original.id);
          }

          function Descargar() {

            console.log(row.original.flename);
            if (row.original.flename === null) {
              alert("Debe crear primero el paquete para poder consultarlo")
            } else {
              handleClientLoad(row.original.id);
            }
          }

          function Editar() {
            sessionStorage.setItem("id_pack_edit", row.original.id);
            sessionStorage.setItem("name_pack_edit", row.original.package_name);
            sessionStorage.setItem("num_pack_edit", row.original.package_number);
            window.location = "/edit_add_bonuses";
          }
          function AddSales() {
            sessionStorage.setItem("idpack", row.original.id);
            sessionStorage.setItem("name_pack", row.original.package_name);
            sessionStorage.setItem("num_pack", row.original.package_number);
            window.location = "/add_sales_bonificaciones";
          }

          return (
            <div>
              <img
                src={Package}
                onClick={CrearPaquete}

                title="Crear Reporte"
                alt=""
              ></img>
              <img
                src={Gsheets}
                onClick={Descargar}

                title="Consultar Reporte"
                alt=""
              >
              </img>&nbsp;
              <img
                src={ADD}
                onClick={AddSales}
                className="centerText, "
                title="Agregar Ventas"
                alt=""
              >
              </img>&nbsp;
              <img
                src={Edit}
                onClick={Editar}
                className="centerText cancel"
                title="Editar Paquete"
                alt=""
              ></img>
              <img
                src={DMS}
                onClick={()=>handle_bonus(row.original.id)}
                className="centerText cancel"
                title="Generar DMS"
                alt=""
              ></img>

            </div>
          );
        },
      },
      {
        Header: "Fecha",
        accessor: "date_register"
      },
      {
        Header: "Número",
        accessor: "package_number",
      },
      {
        Header: "Nombre",
        accessor: "package_name",
      },
      {
        Header: "Total Ventas",
        // accessor: "total_sales",
        accessor: (d) => {
          return d.total_sales
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sales)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sales);
        },
      },
      {
        Header: "Documentos",
        accessor: "total_documents",
      },
      {
        Header: "Productos / Piezas",
        accessor: "total_product_units_compensate",
        //Filter: SelectColumnFilter,
      },
      {
        Header: "Productos",
        accessor: (d) => {
          return d.total_product_compensate
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_product_compensate)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_product_compensate);
        },
      },

      {
        Header: "Comisión",
        //accessor: "total_delivery_commission",
        accessor: (d) => {
          return d.total_delivery_commission
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_delivery_commission)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_delivery_commission);
        },
      },
      {
        Header: "Bonificación",
        //accessor: "total_bonification",
        accessor: (d) => {
          return d.total_bonification
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_bonification)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_bonification);
        },
      },
    ],
    []
  );
  return (
    <div className="TableConsultarPaquetes">
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR REPORTES DE BONIFICACIONES", path: null },
        ]
      }
      ></BREADCRUMBS>

      <div className="tablebonificaciones">
        <div >

          <div style={{ width: "98%", height: "100%", paddingLeft: "20px" }}>

            {isLoading ? (
              <LoadingScreen
                className="LoadinScreen"
                loading={true}
                bgColor="#f1f1f1"
                spinnerColor="#9ee5f8"
                textColor="#676767"
                logoSrc={Spinner}
                text={MensajeBusqueda || "Buscando..."}
              />
            ) : (


              <StickyTable>
                <div className="div2cM01">
                  <input
                    type="text"
                    className="fi"
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                      e.currentTarget.focus();
                    }}
                    placeholder="Fecha inicial"
                    onChange={(event) => setfi(event.target.value)}
                  ></input>

                  <input
                    type="text"
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                      e.currentTarget.focus();
                    }}
                    placeholder="Fecha Final"
                    onChange={(event) => setff(event.target.value)}
                  ></input>
                  <button className="button" id="buttonpackageb" onClick={handleSubmit} type="button">
                    Buscar
                  </button>
                  <button className="button" id="buttonpackageb" style={{ width: "150px" }} onClick={async () => {

                    const result = await CustomDialog(
                      <div>
                        <SetBonuses></SetBonuses>
                      </div>,
                      {
                        className: "modalTItle",
                        title:
                          "Crear nuevo paquete",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );

                  }}
                  >Crear Paquete </button>

                </div>
              </StickyTable>

            )}
          </div>
          <br></br>
          <div className="tablereportsbonif">
            <StickyTable>
              <TableContainer
                paginado={"Reportes"}
                consultas={"Reportes Consultados"}
                exportar={true}
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                nametable={"Bonificaciones"}
              />
            </StickyTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableBonificaciones;
