//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog,useDialog } from "react-st-modal";

//COMPONENTES
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_cabinets } from "../../services/unilever/unilever";
//ESTILOS
import "../../main_components/Table/tablecontainer.css";
import "./dms.css";

//IMAGENES


import MODAL_TABLE from "../../main_components/modal/modal_Table";

//COMPONENTES

const INTERFAZ_CABINETS = () => {

  const [data, setData] = useState([]);
  const [menssage, setmenssage] = useState([]);
  const [modalGeneral, setmodalGeneral] = useState([]);
  const [modalT, setmodalT] = useState(false);

  const dialog = useDialog();


  const handleSubmit = async (event) => {
    event?.preventDefault();
  
    setmodalT(true);
    setData([]);
    try{
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            await get_cabinets();
            setmodalGeneral(false);
            setmodalT(false);
            window.location="/consult_DMS"
          } catch (err) {
            setmodalGeneral(false);
            setmodalT(false);  
            alert( JSON.stringify(err.response.data))
          }   
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  return (
    <div>
 
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      <div>
      <Box sx={{ margin:"0 auto" }}>

          <h4 style={{marginLeft:"13%",fontSize:"20px"}}>¿Estás seguro de generar la interfaz de congeladores?</h4>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
            <Button
              variant="contained"
              sx={{ width: "43%",marginLeft:"3%" }}
              className="ButtonModal"
              onClick={()=>handleSubmit()}
            >
              Generar Interfaz
            </Button>

            <Button
              variant="contained"
              sx={{ width: "43%",marginRight:"3%" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              Cancelar
            </Button>
          </Box>

          <br></br>

        </div>

    </div>

  );
};

export default INTERFAZ_CABINETS;
