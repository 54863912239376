
import { BREADCRUMBS } from "../main_components/pagination/breadcrumbs";
import "./profile.css";
import info from "../imgComponents/iconos/informacion.png";
import priv from "../imgComponents/iconos/privacidad.png";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GppGoodIcon from '@mui/icons-material/GppGood';
import BadgeIcon from '@mui/icons-material/Badge';
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { refresh_token } from "../main_components/tokens/tokenrefresh";
import { get_users } from "../services/configuration/configuration";
import priv2 from "../imgComponents/iconos/seguridad.png";
import load2 from "../imgComponents/iconos/loading.gif";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { get_config_user, post_config_user } from "../services/dashboard/dashboard";
import cloud from "../imgComponents/iconos/nube.png";
import { arrStorage } from "../main_components/menu/data";


export const PROFILE = () => {

  const [user, setUser] = useState(null);
  const [userm, setdatam]=useState([]);
  const nombreCompleto = localStorage.getItem("user_name") 
  var inicial = ((nombreCompleto.split(" "))[0]).charAt(0);
  var r = localStorage.getItem("rolesfav");
  var u =localStorage.getItem("userIdfav");

  const[equal, setequal]=useState(false);
  const[loading, setloading]=useState(false);
  const[fi, setfi]=useState("");
  const[ff, setff]=useState("");
  const[datad, setdatad]=useState([]);

  const[item1, setitem1]=useState(true);
  const[item2, setitem2]=useState(false);
  const[item3, setitem3]=useState(false);
  
  const [showp, setshowp]=useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2]=useState(false);
  const [showPassword3, setShowPassword3]=useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const[newpass1, setnewpass1]=useState("");
  const[newpass2, setnewpass2]=useState("");

  const isLengthValid = newpass1.length >= 8;
  const hasLowerCase = /[a-z]/.test(newpass1);
  const hasUpperCase = /[A-Z]/.test(newpass1);
  const hasNumber = /\d/.test(newpass1);
  const hasSpecialCharacter = /[!@#$%^&*()_+[\]{};':"\\|,.<>?/]/.test(newpass1);

  const [showconf, setshowconf]=useState(false);

  useEffect(() => {
    handleuser();
    handledyna();
    async function fetchUserData() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);     

      } catch (error) {
        console.log('Error al obtener los datos del usuario:', error);
      }
    }
    fetchUserData();
  }, []);

  const handleuser = async ()=>{
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_users();
            const dat = d.data.users.filter(item=>item.email===localStorage.getItem("email_user"))
            setdatam(dat[0]);
            console.log(dat[0])
          }catch(err){
            console.log(err);
          }
        }, 1000);
      }
    }catch(err){
      console.log(err);
    }
  }
  function formatDateAndTime(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}/${padZero(date.getMonth() + 1)}/${padZero(date.getDate())}`;
    const formattedTime = `${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
    return `${formattedDate} ${formattedTime}`;
  }
  function padZero(number) {
    return number.toString().padStart(2, '0');
  }
  const handledyna=async()=>{
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_config_user();
            setdatad(d === null ? [] : d?.data);
            setfi(d?.data?.signin==="" || d?.data?.signin===null || d?.data?.signin===undefined?"":formatDateAndTime(d?.data?.signin));
            setff(d?.data?.signup==="" || d?.data?.signup===null || d?.data?.signup===undefined?"":formatDateAndTime(d?.data?.signup));
            console.log(d?.data);
            setshowconf(false);
          }catch(err){
            setshowconf(false);
            console.log(err);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  }
  const handleitem=(m)=>{
    if(m===1){
      setitem1(true);
      setitem2(false);
      setitem3(false);
    }else if(m===2){
      setitem1(false);
      setitem2(true);
      setitem3(false);
    }else if(m===3){
      setitem1(false);
      setitem2(false);
      setitem3(true);
    }else{}
  }
  const handlepass=()=>{
    setshowp(false);
  }
  const handlepass2=()=>{
    setshowp(true);
  }
  const handlePasswordToggle = () => {setShowPassword(!showPassword);};
  const handlePasswordToggle2 = () => {setShowPassword2(!showPassword2);};
  const handlePasswordToggle3 = () => {setShowPassword3(!showPassword3);};

  const handlecp=(event)=>{
    if(newpass1===event){
      setequal(false);
    }else{
      setequal(true);
    }
  }

  const cognitopass = async () => {

    if(currentPassword===""){
      alert("No ha proporcionado la contraseña actual");
    }else{
      if(newpass1===newpass2){
        if(newpass1.length > 0 && !isLengthValid && !hasLowerCase && !hasUpperCase && !hasNumber && !hasSpecialCharacter){
          alert("No cumple con las especificaciones");           
        }else{
            try {
              setloading(true);
              const user = await Auth.currentAuthenticatedUser();
              const d = await Auth.changePassword(user,currentPassword, newpass1);
              console.log(d);
              setTimeout(() => {
                setloading(false);
                setnewpass1("");
                setnewpass2("");
                setCurrentPassword("");
                setshowp(true);
                alert("contraseña actualizada con éxito");
              }, 2000);
            } catch (error) {
              console.log(error);
              if(error.message==="Incorrect username or password."){
                alert("Contraseña actual incorrecta, verifique su contraseña");
              }
              if(error.message==="Attempt limit exceeded, please try after some time."){
                alert("Se excedió el límite de intentos, intente después de un tiempo");
              }
              else{
                alert("Error al cambiar la contraseña, vuelva a intentarlo");
              }
              setloading(false);
            }
        }
      }else{
        alert("No son iguales las contraseñas");
        setequal(true);
      }
    }
  }

  const analisis=()=>{
      
    const d = arrStorage(r, u);
    const nuevoJSON = d?.map((valor) => {
        if (valor.forStorage === null ||valor.forStorage === undefined || valor.restriccion === "none" ||valor.restriccion === "DENEGADO"
        ) {
          return null; // O puedes devolver un objeto vacío {} si prefieres mantener el elemento en el arreglo pero sin datos.
        } else {
          return {
            ruta: valor.ruta,
            name: valor.name,
          };
        }
      }).filter(Boolean); // Filtramos para eliminar los elementos que fueron mapeados a null (o vacíos) debido a las condiciones.

      return nuevoJSON;
  }
  const handlepost=async(tour)=>{
      
    var nuevoJSON = analisis();
    
    var data ={
        tour: tour,
        favorites: nuevoJSON,
    }
    try{
    setshowconf(true);
    var rf_token = await refresh_token();
    if(rf_token===true){
        try{ 
            const d = await post_config_user(data);
            if(tour==="true"){
                window.location.reload();   
            }else{
                setTimeout(() => {
                  handledyna();
                }, 1500);
            }
        }catch(err){
            console.log(err);
            setshowconf(false);
        }
    }else{}
    }catch(err){
    console.log(err);
    }
  }

  return (
    
    <>
      <BREADCRUMBS niveles={
              [
                {label: "MI PERFIL", path: null},
              ]
            }
    ></BREADCRUMBS>
    <div className='container-profile'>
      <div className="container-profile-grid">
         <div className="menu-lateral">
            <div className="menu-lat-title">
            
            </div>
            <div className={`menu-lateral-item ${item1===true?"selec-item":""}`} onClick={()=>handleitem(1)}>
              <div className="icono">
                <AccountCircleIcon></AccountCircleIcon>
              </div>
              <div className="item">
                Página Principal
              </div>
            </div>
            <div className={`menu-lateral-item ${item2===true?"selec-item":""}`} onClick={()=>handleitem(2)}>
              <div className="icono"> 
              <BadgeIcon></BadgeIcon>
              </div>
              <div className="item">
                Información Personal
              </div>
            </div>
            <div className={`menu-lateral-item ${item3===true?"selec-item":""}`} onClick={()=>handleitem(3)}>
              <div className="icono">
                <GppGoodIcon></GppGoodIcon>
              </div>
              <div className="item">
                Seguridad
              </div>
            </div>
            
         </div>
         {
          item1===true?
          <div className="dash-profile">
            <div className="info-user">
              <div className="circle-init">
                  <p>{inicial}</p>
              </div>
              <div className="name-info">
                  <p className="name-completed">
                    Bienvenido, {nombreCompleto}
                  </p>
                  <p className="text-1">
                    Administra tu información y las opciones de privacidad y seguridad 
                    a fin de que OD2 sea más relevante para ti.
                  </p>
              </div>
              <div className="secciones-info">
                <div className="info-personal cart1" onClick={()=>handleitem(2)}>
                  <p className="title-cart">Información Personal</p>
                  <div className="desc-cart"> 
                    <p className="desc-p-cart">Encontrarás tu información de perfil y las opciones para administrarla</p>
                    <img src={info} className="desc-img-cart"></img>
                  </div>
                </div>
                <div className="info-personal cart2" onClick={()=>handleitem(3)}>
                  <p className="title-cart">Seguridad</p>
                  <div className="desc-cart"> 
                    <p className="desc-p-cart">Encontrarás tú información de inicio y cierre de sesión.</p>
                    <img src={priv} className="desc-img-cart"></img>
                  </div>
                </div>
              </div>
              <div className="configuration_user_dynamo">
                <div className="info-conf-user">
                  <div className="dyn-1">
                    <p className="title-cart">Configuración Guardada de Usuario</p>
                    <p className="desc-p-dyn">
                      Te informamos que hemos implementado una función para mejorar la experiencia de tu cuenta.
                      A partir de ahora, la información guardada en tu cuenta se mantendrá sincronizada y disponible 
                      en todos tus dispositivos.
                    </p>
                    <p className="desc-p-dyn">
                      Estamos seguros de que esta mejora hará que tu experiencia sea mucho más fluida y eficiente. 
                      Ahora podrás llevar un seguimiento de tu configuración de una manera más sencilla y accesible.
                    </p>
                  </div>
                  <div className="dyn-2">
                    <img  src={cloud}></img>
                  </div> 
                </div>
                {
                    showconf===true?
                    <div className="loading">
                      <img src={load2} alt=""></img>
                      <p className="gif-p">Actualizando Configuración...</p>
                    </div>:
                    <>
                     <div className="configuration-1">
                        <p className="confp">Tour Inicial: </p><p className="confpp">{datad.tour}</p>
                      </div>
                      <div className="configuration-2">
                        <p className="confp">Módulos Favoritos:</p>
                        <div className="grid-container-fav-conf">
                          {
                            datad?.favorites?.map((item, index)=>(
                              <div key={index} className="grid-item-fav-conf">
                                <li>{item.name}</li>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className="configuration-3">
                      <div className="conf3-1">
                        <p className="conf3-text">
                          Nos complace informarte que hemos añadido una nueva funcionalidad que te permitirá 
                          cargar tu configuración actual de forma rápida y sencilla. Ahora, con solo presionar 
                          un botón, podrás subir y aplicar tu configuración preferida en cualquier momento.
                        </p>
                      </div>
                      <div className="conf3-2">
                        <buttom className="button-save-config" onClick={()=>handlepost("false")}>Actualizar</buttom>
                      </div>
                  </div>  
                    </>
                }
              </div>
            </div>
         </div>:<></>
         }
         {
          item2===true?
          <div className="seccion-info-personal">
            <div className="info-descripcion">
              <p className="info-des-title">Información personal</p>
              <p className="info-des-p">Información sobre ti y tus preferencias en los servicios de Operación Dinamica OD2</p>
            </div>
            <div className="info-basic">
              <p className="info-basic-title">Información Básica</p>
              <p className="info-basic-text">Es posible que cierta información sea visible para otros usuarios que usan los servicios de Operación Dinámica</p>
                <div className="formulario">
                  <p className="text-form">Nombre Completo: </p> <span>{nombreCompleto}</span>
                </div>
                <div className="formulario">
                  <p className="text-form">Usuario: </p> <span>{user?.username}</span>
                </div>
                <div className="formulario">
                  <p className="text-form">RFC de la Empresa:</p> <span>{user?.attributes['custom:rfc_company']}</span>
                </div>
                <div className="formulario formulario-close">
                  <p className="text-form">Puesto:</p> <span>{userm?.job}</span>
                </div>
              </div>
              <div className="info-basic">
                <p className="info-basic-title">Información de contacto</p>
                <div className="formulario">
                  <p className="text-form">Correo Electrónico: </p> <span>{userm?.email}</span>
                </div>
                <div className="formulario">
                  <p className="text-form">Número de Teléfono: </p> <span>{userm?.phone}</span>
                </div>
               </div>
               <div className="info-basic">
                <p className="info-basic-title">Permisos del Usuario</p>
                <p className="info-basic-text">Información de los roles que tiene el usuario para consultar los módulos y funcionalidades 
                de OD2.</p>
                <div className="roles">
                    {
                      userm?.roles?.map((item, index)=>(
                        <p key={index} className="role-item">{item}</p>
                      ))
                    }
                </div>
               </div>
            </div>  
            :<></>
         }
         {
          item3===true?
          <div className="container-seguridad">
            <div className="info-descripcion">
              <p className="info-des-title-priv">Seguridad</p>
              <p className="info-des-p">Configuración y recomendaciones para ayudarte a proteger tu cuenta</p>
            </div>

            <div className="container-privacidad-text">
              <div className="cont-priv-t1">
                <p className="title-priv-text"> Protegiendo Tú Experiencia en Línea</p>
                <p>En OD2, entendemos que la seguridad es fundamental para brindarle una experiencia en línea tranquila y confiable.</p>
                <p>
                  Nuestro equipo de expertos en seguridad trabaja incansablemente para mantener nuestros sistemas protegidos 
                  contra amenazas y vulnerabilidades. Implementamos medidas de seguridad de última generación para garantizar 
                  que su información esté siempre protegida y fuera del alcance de terceros no autorizados.
                </p>
              </div>
              <div className="cont-priv-t2">
                <img src={priv2} alt=""></img>
              </div>
            </div>

            <div className="container-change-pass">
              <div className="change-pass">
                <div className="pass-secc1">
                  <p className="title-pass">Contraseña</p>
                  <p className="text-pass">Una contraseña segura te ayuda a proteger tu cuenta de OD2</p>
                </div>
                <div className="pass-secc2">
                  {
                    showp===true?
                    <div className="botton-pass" onClick={()=>handlepass()}>Cambiar Contraseña <ArrowDropDownIcon></ArrowDropDownIcon></div>
                    :
                    <div className="botton-pass" onClick={()=>handlepass2()}>Cancelar <ArrowDropUpIcon></ArrowDropUpIcon></div>
                  }
              </div>
              </div>
              {
                loading===false?
                <>
                  <div className="pass-change-method" style={showp===true?{"display":"none"}:{"display":""}}>
                <div className="input-1">
                  <p className="title-pass-change">Contraseña Actual: </p>
                  <input 
                    className="input-p" 
                    type={showPassword ? 'text' : 'password'}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value?.trim())}
                    placeholder="Ingresa tú contraseña">
                  </input>
                  <label className="checkbox-label">
                      <input type="checkbox" checked={showPassword} onChange={handlePasswordToggle}/>
                      Mostrar contraseña
                  </label>
                </div>
                <div className="input-2">
                  
                </div>
                  </div>
                  <div className="pass-change-method" style={showp===true?{"display":"none"}:{"display":""}}>
                  <div className="input-1">
                    <p className="title-pass-change">Nueva Contraseña</p>
                      <input 
                        className="input-p2" 
                        type={showPassword2 ? 'text' : 'password'}
                        value={newpass1}
                        // onBlur={(e)=>handlecp(newpass2)}
                        onChange={(e) => setnewpass1(e.target.value?.trim())}
                        placeholder="Nueva Contraseña">
                        
                      </input>
                      <label className="checkbox-label">
                        <input type="checkbox" checked={showPassword2} onChange={handlePasswordToggle2}/>
                        Mostrar contraseña
                    </label>
                    <div className="expr-reg">
                      {newpass1.length > 0 && !isLengthValid && <p>La contraseña debe tener al menos 8 caracteres.</p>}
                      {newpass1.length > 0 && !hasLowerCase && <p>La contraseña debe contener al menos una minúscula.</p>}
                      {newpass1.length > 0 && !hasUpperCase && <p>La contraseña debe contener al menos una mayúscula.</p>}
                      {newpass1.length > 0 && !hasNumber && <p>La contraseña debe contener al menos un número.</p>}
                      {newpass1.length > 0 && !hasSpecialCharacter && (
                        <p>La contraseña debe contener al menos un carácter especial.</p>
                      )}
                    </div>
                  </div>
                  <div className="input-2">
                    <p className="title-pass-change">Confirmar Nueva Contraseña</p>
                      <input 
                        className="input-p2" 
                        type={showPassword3 ? 'text' : 'password'}
                        value={newpass2}
                        onChange={(e) => setnewpass2(e.target.value?.trim())}
                        onBlur={(e)=>handlecp(e.target.value)}
                        placeholder="Nueva Contraseña">
                        
                      </input>
                      <label className="checkbox-label">
                        <input type="checkbox" checked={showPassword3} onChange={handlePasswordToggle3}/>
                        Mostrar contraseña
                    </label>
                    <div className="expr-reg">
                      {equal===true?<p>La contraseña no es la misma.</p>:<></>}
                    </div>
                  </div>
                  </div>
                  <div className="acciones-boton" style={showp===true?{"display":"none"}:{"display":""}}>
                  <div className="button-change">
                    <button  onClick={()=>cognitopass()}>Cambiar Contraseña</button>
                  </div>
                </div>
                </>:
                 <div className="loading">
                    <img src={load2} alt=""></img>
                    <p className="gif-p">Cambiando Contraseña...</p>
                 </div>
              }
             
             
             
            </div>
            <div className="info-session-time">
              <div className="info-s-1">
                <p className="title-session">Tú Actividad</p>
                <p className="p-session"> Tendrás acceso a información detallada sobre la fecha y hora del último registro de ingreso a tu cuenta y último cierre de sesión. 
                  Esto te ayudará a mantener un control más efectivo de tus actividades en la plataforma y a asegurarte de que solo tú
                  tengas acceso a tu cuenta.
                </p>
              </div>
              <div className="info-s-2">
                <p className="title-session">Último Inicio de Sesión:</p>
                <p className="time-ss">{fi===""?"Sin Registro":fi}</p>
              </div>
              <div className="info-s-3">
                <p className="title-session">Último Cierre de Sesión:</p>
                <p className="time-ss">{ff===""?"Sin Registro":ff}</p>
              </div>
              
             
            </div>
          </div>:<></>
         }
         
      </div>
    </div>
    </>
    
  )
} 
