import { BREADCRUMBS } from '../../main_components/pagination/breadcrumbs'
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import Button from "@mui/material/Button";
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import TableContainer from "../../main_components/Table/TableContainer";
import { pagination } from '../../main_components/pagination/pagination';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { formattedCurrentDate, formattedPreviousDate, formattedPreviousDate_f, formattedCurrentDate_f } from "../../main_components/date/day";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MaterialUISwitch } from "../../main_components/material_switch/switch";

import "./balances.css";
import { TextField } from '@mui/material';

export const BALANCES = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);

  const [open, setopen] = useState(false);
  var entrada = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  const [fi, setfi] = useState(formattedPreviousDate);
  const [ff, setff] = useState(formattedCurrentDate);
  const [id_partner, setid_partner] = useState(null);
  const [id_pos, setid_pos] = useState(null);
  const [pay, setpay] = useState(0);
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const [search, setsearch] = useState(true);
  const [pdfconfig_data, setpdfconfig_data] = useState("");

  

  const [namepar, setnamepar] = useState("");
  const [namepos, setnamepos] = useState("");

  const deudatype = [
    { "value": 0, "label": "Pendientes de pagar" },
    { "value": 1, "label": "Deudas pagadas" }
  ]

  const columns = useMemo(
    () => [
      {
        Header: "Factura",
        accessor: "folio_invoice"
      },
      {
        Header: "Ticket",
        accessor: "ticket"
      },
      {
        Header: "Fecha Factura",
        accessor: "invoice_date"
      },
      {
        Header: "Al corriente",
        id: "alcor",
        accessor: (d) => {
          return d.overdraft_days === 0 ?
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sale) :
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(0.0)
        },
        Cell: ({ value }) => {
          if (value === "$0.00") {
            return (
              <div className='ocultar-ant-sald'>
                {value}
              </div>
            )
          } else {
            return (
              <div className=''>
                {value}
              </div>
            )
          }
        }
      },
      {
        Header: "1-30",
        id: "130",
        accessor: (d) => {
          return d.overdraft_days >= 1 && d.overdraft_days <= 30 ?
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sale) :
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(0.0)
        },
        Cell: ({ value }) => {
          if (value === "$0.00") {
            return (
              <div className='ocultar-ant-sald'>
                {value}
              </div>
            )
          } else {
            return (
              <div className=''>
                {value}
              </div>
            )
          }
        }
      },
      {
        Header: "31-60",
        id: "3160",
        accessor: (d) => {
          return d.overdraft_days >= 31 && d.overdraft_days <= 60 ?
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sale) :
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(0.0)
        },
        Cell: ({ value }) => {
          if (value === "$0.00") {
            return (
              <div className='ocultar-ant-sald'>
                {value}
              </div>
            )
          } else {
            return (
              <div className=''>
                {value}
              </div>
            )
          }
        }
      },
      {
        Header: "61-90",
        id: "6190",
        accessor: (d) => {
          return d.overdraft_days >= 61 && d.overdraft_days <= 90 ?
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sale) :
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(0.0)
        },
        Cell: ({ value }) => {
          if (value === "$0.00") {
            return (
              <div className='ocultar-ant-sald'>
                {value}
              </div>
            )
          } else {
            return (
              <div className=''>
                {value}
              </div>
            )
          }
        }
      },
      {
        Header: "91 o más",
        id: "91",
        accessor: (d) => {
          return d.overdraft_days >= 91 ?
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sale) :
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(0.0)
        },
        Cell: ({ value }) => {
          if (value === "$0.00") {
            return (
              <div className='ocultar-ant-sald'>
                {value}
              </div>
            )
          } else {
            return (
              <div className=''>
                {value}
              </div>
            )
          }
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );



  const columns2 = useMemo(
    () => [
      {
        Header: "Socio de Negocios",
        accessor: "name_business_partner",
        className: "justify_data"
      },
      {
        Header: "Factura",
        accessor: "folio_invoice"
      },
      {
        Header: "Ticket",
        accessor: "ticket"
      },
      {
        Header: "Fecha Factura",
        accessor: "invoice_date"
      },
      {
        Header: "Al corriente",
        id: "alcor",
        accessor: (d) => {
          return d.overdraft_days == 0 ?
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sale) :
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(0.0)
        },
        Cell: ({ value }) => {
          if (value === "$0.00") {
            return (
              <div className='ocultar-ant-sald'>
                {value}
              </div>
            )
          } else {
            return (
              <div className=''>
                {value}
              </div>
            )
          }
        }
      },
      {
        Header: "1-30",
        id: "130",
        accessor: (d) => {
          return d.overdraft_days >= 1 && d.overdraft_days <= 30 ?
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sale) :
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(0.0)
        },
        Cell: ({ value }) => {
          if (value === "$0.00") {
            return (
              <div className='ocultar-ant-sald'>
                {value}
              </div>
            )
          } else {
            return (
              <div className=''>
                {value}
              </div>
            )
          }
        }
      },
      {
        Header: "31-60",
        id: "3160",
        accessor: (d) => {
          return d.overdraft_days >= 31 && d.overdraft_days <= 60 ?
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sale) :
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(0.0)
        },
        Cell: ({ value }) => {
          if (value === "$0.00") {
            return (
              <div className='ocultar-ant-sald'>
                {value}
              </div>
            )
          } else {
            return (
              <div className=''>
                {value}
              </div>
            )
          }
        }
      },
      {
        Header: "61-90",
        id: "6190",
        accessor: (d) => {
          return d.overdraft_days >= 61 && d.overdraft_days <= 90 ?
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sale) :
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(0.0)
        },
        Cell: ({ value }) => {
          if (value === "$0.00") {
            return (
              <div className='ocultar-ant-sald'>
                {value}
              </div>
            )
          } else {
            return (
              <div className=''>
                {value}
              </div>
            )
          }
        }
      },
      {
        Header: "91 o más",
        id: "91",
        accessor: (d) => {
          return d.overdraft_days >= 91 ?
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total_sale) :
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(0.0)
        },
        Cell: ({ value }) => {
          if (value === "$0.00") {
            return (
              <div className='ocultar-ant-sald'>
                {value}
              </div>
            )
          } else {
            return (
              <div className=''>
                {value}
              </div>
            )
          }
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handle_deudas = async () => {

    console.log(id_partner);
    console.log(id_pos);

    try {
      setmodalT(true);
      setData([]);
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await pagination(
              7,
              "debts",
              null,
              null,
              fi,
              ff,
              id_pos,
              id_partner,
              pay
            );
            var dd = dt.filter(item => item.folio_invoice !== null);
            dd.sort((a, b) => {
              // Asumiendo que invoice_date es una cadena de fecha en formato "YYYY-MM-DD"
              // Si es otro formato, ajusta la lógica de comparación en consecuencia
              const dateA = new Date(a.invoice_date);
              const dateB = new Date(b.invoice_date);

              return dateA - dateB;
            });

            setData(dd);
            setmodalT(false);
          } catch (err) {
            console.log(err);
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }
  const handleSubmit2 = async (a, b) => {
    try {
      try {
        var dt = await pagination(5, "aux1", null, null, null, null);
        setdata2(dt);
      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (err) {
      setData([]);
    }
  };
  const handleSubmit3 = async (a, b) => {
    try {
      try {
        var dt = await pagination(5, "aux2", null, null, null, null);
        setdata3(dt);
      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (err) {
      setData([]);
    }
  };

  useEffect(() => {
    handleSubmit2();
    handleSubmit3();
  }, [])


  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };

  const handleOnSelect = (item) => {
    setid_pos(item?.id === undefined ? null : item?.id);
    setid_partner(null);

    const d = (item?.id === undefined ? "" : data2.filter(iden => iden.id === item?.id)[0]);
    setnamepar(d);
  };

  const handleOnSelect2 = (item) => {
    setid_pos(null);
    setid_partner(item?.id === undefined ? null : item?.id);

    const d = (item?.id === undefined ? "" : data3.filter(iden => iden.id === item?.id)[0]);
    setnamepos(d);
  };

 

  useEffect(()=>{
     const pdfconfig = {
      "size": 9,
      "orientation": "p",
      "title": Number(pay) === 0 ? "Antigüedad de Saldos" : "Antigüedad de Facturas Pagadas",
      "title_center":  "Antigüedad de Saldos",
      "title2":
        id_partner === null && id_pos === null ?
          "Socio de Negocios: Todos" :
          id_pos === null ? `Punto de Venta: ${namepos.code} - ${namepos.name}` :
            id_partner === null ? `Socio de Negocios: ${namepar.code} - ${namepar.business_partner}` : "",
      "border": true,
      "fi": fi,
      "ff": ff
    };

    setpdfconfig_data(pdfconfig)
  },[pay])

  console.log(pay)
  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "ANTIGÜEDAD DE SALDOS", path: null },
        ]
      }
      ></BREADCRUMBS>

      <div className='balances-old-container'>
        <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
        <MODAL_TABLE
          open={true}
          message={menssage}
          modalGeneral={modalGeneral}
          modalT={modalT}
        ></MODAL_TABLE>
        <div className='filtros_consult_balances_old'>

          <div style={{ display: search === true ? "none" : "block" }}>
            <div
              className="search_component"
              style={{ pointerEvents: id_partner === null ? "all" : "none" }}
            >
              <ReactSearchAutocomplete
                items={data2}
                fuseOptions={{ keys: ["id", "business_partner"] }}
                resultStringKeyName={"business_partner"}
                onSelect={handleOnSelect}
                onClear={handleOnSelect}
                showIcon={true}
                maxResults={20}
                placeholder="Socio de negocios..."
                styling={{
                  height: "34px",
                  border: "1px solid darkgreen",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  hoverBackgroundColor: "lightgreen",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 20,
                }}
              />
            </div>

            <br></br>

            <div
              className="search_component"
              style={{ pointerEvents: id_pos === null ? "all" : "none" }}
            >
              <ReactSearchAutocomplete
                items={data3}
                fuseOptions={{ keys: ["id", "name"] }}
                resultStringKeyName={"name"}
                onSelect={handleOnSelect2}
                onClear={handleOnSelect}
                showIcon={true}
                maxResults={20}
                placeholder="Punto de venta..."
                styling={{
                  height: "34px",
                  border: "1px solid darkgreen",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  hoverBackgroundColor: "lightgreen",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 15,
                }}
              />
            </div>
          </div>

          <div className="containersaledebts">
            <div className="sdbts-1 color-1">
              <div className="fdebts">
                <input
                  type="text"
                  className="fdebts"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder={formattedPreviousDate_f}
                  onChange={(event) => setfi(event.target.value)}
                ></input>
              </div>
            </div>

            <div className="sdbts-2 color-2">
              <div className="fdebts">
                <input
                  type="text"
                  className="fdebts"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder={formattedCurrentDate_f}
                  onChange={(event) => setff(event.target.value)}
                ></input>
              </div>
            </div>

            <div className="sdbts-3 color-5">
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    sx={{ m: 1 }}
                    onChange={(e) => setsearch(e.target.checked)}
                    checked={search}
                  />
                }
                label={
                  search === true
                    ? "Activar Filtros de Busqueda"
                    : "Desactivar Filtros de Busqueda"
                }
              />
            </div>
            <div className="sdbts-4 color-5">
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() =>
                  handle_deudas(false, "Deudas consultadas correctamente")
                }
              >
                Buscar
              </Button>
            </div>
            <div className='sdbts-5 color-5 selec-deuda-type'>
              <TextField
                select
                label="Deuda tipo"
                value={pay}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) =>
                  setpay(event.target.value)
                }
              >
                {
                  deudatype.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))
                }
              </TextField>
            </div>
          </div>

        </div>
        <div className='table_saldos_old'>
          <StickyTable>
            <TableContainer
              paginado={"Saldos"}
              consultas={"Saldos Consultados"}
              nametable={"Antiguedad de Saldos"}
              exportar={true}
              columns={id_pos === null || id_pos === "" ? columns2 : columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
              Grantotal={true}
              GrantotalCabeceras={["Factura", "Ticket"]}
              report_old_balances={true}
              pdfconfig={pdfconfig_data}
            />
          </StickyTable>
        </div>

      </div>
    </>
  )
}
