import React, { useEffect, useMemo, useState } from 'react'
import { refresh_token } from '../main_components/tokens/tokenrefresh';
import { StickyTable } from "react-sticky-table";
import TableContainer from '../main_components/Table/TableContainer';
import { get_sales_in_real_time, get_sales_in_real_time_v2 } from '../services/reports/reports';
import { get_route_list } from '../services/businesspartners/businesspartners';
import TextField from "@mui/material/TextField";
import { Checkbox } from "@material-ui/core";
import "./mercado.css";
import MODAL_TABLE from '../main_components/modal/modal_Table';
import { Bar, Brush, CartesianGrid, ComposedChart, Label, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { get_products } from '../services/inventory/inventory';
import r1 from "./RUTA 1.json";
import r2 from "./RUTA 2.json";
import r3 from "./RUTA 3.json";
import r4 from "./RUTA 4.json";
import r5 from "./RUTA 5.json";
import r6 from "./RUTA 6.json";
import r7 from "./RUTA 7.json";


export const MERCADO_STUDY = () => {

    const [fecha, setfecha]=useState("");
    const [fechafinal, setfechafinal]=useState("");

    const [nameroute, setnameroute]=useState(0);
 
    const [routedata, setroutedata]=useState([]);
    const [datap, setdatap]=useState([]);
    const [modalT, setmodalT]=useState(false);

    const [data, setdata]=useState([]);
    const [datag, setdatag]=useState([]);

    const [clas1, setclas1]=useState(true);
    const [clas2, setclas2]=useState(false);
    const [clas3, setclas3]=useState(false);

    const [d, setd]=useState(true);
    const [orden, setorden]=useState("");


const handle = async() =>{
    try{
      setmodalT(true);
        var rf_token = await refresh_token();
        if(rf_token===true){
            setTimeout(async() => {
              try{
                const d = await get_sales_in_real_time_v2(nameroute, fecha, fechafinal);
                setclas1(true);
                setclas2(false);
                
                analisis(d);

              }catch(err){
                console.log(err);
                setmodalT(false);
                setdata([])
              }
                
            }, 1000);
        }else{}

    }catch(err){
    console.log(err);   
    }
}
const analisis = (d) =>{
    console.log(d.data);

    var array = [];
    d?.data?.points_of_sale?.map(item=>{
        item?.sales?.map(j=>{
          
            var dataaux = [];
            var datareturn = [];

            j?.details.map(k=>{   
                var data = {
                    code: k?.code,
                    product_name: k?.product_name,
                    quantity: k?.quantity
                }
                dataaux.push(data);
             })
            if(j?.returns.length>0){ 
                console.log(j);
                j?.returns?.map(k=>{
                    if(k?.type==="DEVOLUCION"){
                        k?.details?.map(l=>{
                            var data = {
                                code: l?.code,
                                product_name: l?.product_name,
                                quantity: l?.quantity
                            }
                            datareturn.push(data);
                        })
                    }
                })  
            }    
            
            if(datareturn.length>0){
                const cantidadesARestar = {};
                datareturn.forEach((producto) => {
                    const { code, quantity } = producto;
                    if (cantidadesARestar[code]) {
                      cantidadesARestar[code] += quantity;
                    } else {
                      cantidadesARestar[code] = quantity;
                    }
                  });
                dataaux.forEach((producto) => {
                    const { code } = producto;
                    if (cantidadesARestar[code]) {
                    producto.quantity -= cantidadesARestar[code];
                    }
                });
            }      
            
            if(dataaux.length>0){
               const df = dataaux.filter(item=>item.quantity!==0);
               df.map(item => {
                array.push(item);
               });
            }
        })
    })

const sumasPorCodigo = {};
array.forEach((producto) => {
  const { code, product_name, quantity } = producto;
 if (sumasPorCodigo[code]) {
    sumasPorCodigo[code].quantity += quantity;
  } else {
    sumasPorCodigo[code] = {
      code,
      product_name,
      quantity,
    };
  }
});
  const resultado = Object.values(sumasPorCodigo);
  resultado.map(item=>{
    datap.map(j =>{
      if(item?.code === j?.code){
        item.brand = j?.brand;
        item.type = j?.type;
      }
    })
  })

  setdata(resultado);
  setdatag(resultado);
  setmodalT(false);
} 
const columns = useMemo(
    () => [
      {
        Header:"Código Producto",
        accessor:"code"
      },
      {
         Header:"Nombre de Producto",
         accessor:"product_name"
      },
      {
        Header: "Cantidad",
        accessor: "quantity"
      }
    ],
    []
);
const handleroute =async () => {
    try {
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_route_list();
            setroutedata(d === null ? [] : d)     
          }catch(err){
            console.log(err);
          }
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    }
}
const handleproducts = async() =>{
  try{
    var rf_token = await refresh_token();
    if(rf_token === true){
      setTimeout(async() => {
        try{
          const d = await get_products(null, false);
          console.log(d);
          setdatap(d.data);
        }
        catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
}
useEffect(() => {
  handleroute();
  handleproducts();
}, [])
const clasificacion = () =>{    

  if(clas2===true){
    setclas1(false);
    setclas2(false);
    setdatag([]);
  }else{
    setclas1(false);
    setclas2(true);
    setorden("vacio");

  const agrupadoPorBrand = {};
  data.forEach(objeto => {
    const brand = objeto.brand;
    const quantity = objeto.quantity;
    if (!agrupadoPorBrand[brand]) {
      agrupadoPorBrand[brand] = { brand: brand, quantity: 0, productos: [] };
    }
    agrupadoPorBrand[brand].quantity += quantity;
    agrupadoPorBrand[brand].productos.push(objeto);
  });

  const resultado = Object.values(agrupadoPorBrand);
  setdatag(resultado);
  }
}
const clasificaciondefault = () =>{
  if(clas1===true){
    setclas1(false);
    setclas2(false);
    setdatag([]);
  }else{
    setclas1(true);
    setclas2(false);
    setorden("vacio");
    const d = data;
    setdatag(d);
  }
}
const handleordenacion = (a)=>{
  setd(!d);
  setorden(a);
  if(a==="ASC"){
    const dataasc = datag.sort((a, b) => a.quantity - b.quantity);
    setdatag(dataasc);
  }
  else if(a === "DESC"){
    const datadesc = datag.sort((a, b) => b.quantity - a.quantity);
    setdatag(datadesc);
  }else{
    
  }
}
useEffect(() => {
  console.log("");
}, [d])


const[dataanual, setdatanual]=useState("");

const [g, setg]=useState(false);

const agrupanual=()=>{

  const agrupado = r1.reduce((acc, item) => {
    const mes = item.Mes.split(":")[1];
    if (!acc[mes]) {
      acc[mes] = {
        mes: "",
        cantidad: 0,
        productos: []
      };
    }
    acc[mes].mes = mes?.trim();
    acc[mes].cantidad += item.Cantidad;
    acc[mes].productos.push(item);
    return acc;
  }, {});

  const resultadoArray = Object.values(agrupado);

  console.log(resultadoArray);
  // setdatanual(r1);
  setdatag(resultadoArray);
}



    return (
<div className="contend-table-prod">
<MODAL_TABLE
    open={true}
    message={""}
    modalGeneral={false}
    modalT={modalT}
  ></MODAL_TABLE>


<div className='grid-real-products'>
        <div className='grp1'>
           <input
              type="text"
              className="fecha1"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha inicial"
            onChange={(event) => setfecha(event.target.value)}
            ></input>
        </div>
        <div className='grp2'>
           <input
              type="text"
              className="fecha1"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha final"
            onChange={(event) => setfechafinal(event.target.value)}
            ></input>
        </div>
        <div className='grp3'>
        <TextField
          select
          required
          label="Ruta"
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setnameroute(event.target.value)}
      >
          <option key={0} value={"vacio"} selected>
              {"Seleccione Ruta"}
          </option>
          {
            routedata?.data?.parametersList?.map((item)=>(
              <option key={item.idRoute} value={item.route}>
                {item.route}
              </option>
            ))
          }
      </TextField>
        </div>
        <div className='grp4'>
          <button className="button" id="buttonpackageb" onClick={handle} type="button">
              Buscar
          </button>
        </div>
        <div className='grp5'>
          {
            g===false?
            <button className='button' onClick={()=>{setg(true)}}>
              Gráfica
            </button>:
            <button className='button' onClick={()=>{setg(false)}}>
              Tabla
            </button>
          }
          
        </div>
        <div className='grp6'>
          <button className='button' onClick={()=>agrupanual()}>ANUAL</button>
        </div>
        {/* <div className='grp6'>
          <button className='button' onClick={()=>revocar()}>revocar token</button>
        </div> */}
      </div>
      {
        g===true?
        <div className=''>
          <div className='filtros_clasificacion'>
            <div className='fultro1-prod-real2'>
              <TextField
                select
                label="Orden"
                InputLabelProps={{shrink: true,}}
                value={orden}
                SelectProps={{native: true,}}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => handleordenacion(event.target.value)}
              >
              <option key={0} value={"vacio"} selected>
                  {"Selecciona"}
              </option>
              <option key={1} value={"ASC"}>
                {"ASCENDENTE"}
              </option>
              <option key={2} value={"DESC"}>
                {"DESCENDENTE"}
              </option>
              </TextField>
            </div>
            <div className='fultro1-prod-real2'>
            <label className="labelcheck">Productos 
              </label>
                <Checkbox
                  checked={clas1}
                  onChange={()=>{
                    clasificaciondefault();
                  }}
                  inputProps={{"aria-label": "primary checkbox"}}
                  style={{"padding":"10px", "transform":"scale(1.7)"}}
                />
            </div>
            <div className='fultro1-prod-real3'>
            <label className="labelcheck">Clasificación 
              </label>
                <Checkbox
                  checked={clas2}
                  onChange={()=>{
                    clasificacion();
                  }}
                  inputProps={{"aria-label": "primary checkbox"}}
                  style={{"padding":"10px", "transform":"scale(1.7)"}}
                />
            </div>
          </div>
          
      
        <ResponsiveContainer width="95%" maxHeight={450} minHeight={500}>
          <ComposedChart
            data={datag}
            margin={{
              top: 20,
              right: 50,
              left: 50,
              bottom: 10
            }}
        > 
          <CartesianGrid strokeDasharray="3 3" />
            
            {
              clas3===true?            
              <>
               <XAxis dataKey="mes"></XAxis>
               <YAxis dataKey="cantidad"/>
                  <Brush dataKey="Cantidad" height={30} stroke="#8884d8" />  
                  <Tooltip
                    formatter={(value, name, props)=>{
                      return `${value}`;
                    }}
                  />
              </>:
              <>
                {
                  clas1===true?
                    <XAxis dataKey="product_name" display={"none"}> 
                      <Label value="Productos" offset={-10} position="end"/>
                    </XAxis>:
                  clas2 === true?
                    <XAxis dataKey="brand"></XAxis>:<></>
                }
                  <YAxis dataKey="quantity"/>
                  <Brush dataKey="quantity" height={30} stroke="#8884d8" />  
                  <Tooltip
                    formatter={(value, name, props)=>{
                      return `${value}`;
                    }}
                  />
              </>
            }
            
        
          <Legend verticalAlign="top" height={50}/>
          <ReferenceLine y={0} stroke="#000" />  
           {
            clas3===true?
            <>
            {
              datag.map(item=>{
                item.productos.map(it=>{
                  console.log(it.cantidad)
                  return(
                    <Line dataKey={it.cantidad} stackId="a" name="Cantidad" fill="#00CFC3"/>   
                  )
                }
                  
                )
              })
            }
              
            </>:
              <Bar dataKey="quantity" stackId="a" name="Cantidad" fill="#00CFC3"/>
           }
                                 
          </ComposedChart>
        </ResponsiveContainer>
        </div>:
        <div className='produ-table-real'>
        <StickyTable>
            <TableContainer
              paginado={"Productos"}
              consultas={"Productos consultadas"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Grantotal={true}
              GrantotalCabeceras={"Código"}
            />
          </StickyTable>
      </div>
      }
      
     
    </div>
  )
}
