//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import '../../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../../components_cfdi/modal.css";
import "./goals.css";
//IMAGENES/ICONOS
import Delete from "../../../imgComponents/borrar.png";
import CREATE from "../../../imgComponents/create.png";
import Edit from "../../../imgComponents/save.png";
import CreateGoal from "./CreateGoal";
import EditGoal from "./EditGoal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { delete_goal, get_goals } from "../../../services/reports/reports";
import TableContainer from "../../../main_components/Table/TableContainer";

const TableGoal= (props) => {
  
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [my, setmy]=useState(props.my===null?null:props.my);
  console.log(props);
  
useEffect(()=>{
  handleSubmit();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}

const handleSubmit = async () => {

    setmodalT(true);
    setopen(true);
    setData([]);

    var year = null;
    var month = null
    if(my===null){
    }else{
      const partes = my.split("-"); // Divide la cadena en dos partes: año y mes
      year = parseInt(partes[0]); // Convierte el año en un número entero
      month = parseInt(partes[1]); // Convierte el mes en un número entero
    }
    
    try {
      var rf_token = await refresh_token();
      if(rf_token ===true){
        setTimeout(async() => {
          try{
            var d = await get_goals(year, month);
            props.setdatameta(d===null?[]:d.data);
            const sortedRoutes = [...d.data].sort((a, b) => parseInt(a.route) - parseInt(b.route));
            setData(sortedRoutes);
            setopen(false);
            setmodalT(false);
          }catch(err){
            setData([]);
            setopen(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}     
    } catch (err) {
      console.log(err);
    }
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearMeta"
            src={CREATE}
            onClick={() => {
              CustomDialog(               
             <div>
                <CreateGoal handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar} setactualizacionmeta={props.setactualizacionmeta} setfiltrometas={props.setfiltrometas}></CreateGoal>
              </div>,
           {
             className: "modalTItle",
             title:"Crear nueva Meta",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Meta"
            className=""
            alt=""
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ row }) => {
      const DeleteSector = async () => {  
      setmodalT(true);
      setData([]);
      setopen(true);
        try {
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try{
                const dt = await delete_goal(row.original.id);
                console.log(dt);
                alertas("Meta Eliminada", true);
                props.setfiltrometas(false);
                props.setactualizacionmeta(true);
                handleSubmit();
              }catch(err){
                console.log("error", err);
                setopen(false);
                alertas("Error, no se pudo eliminar la meta", false);
                setmodalT(false);
                handleSubmit();
              }
            }, 1000);
          }else{}
        } catch (err) {
         console.log(err);
        } 
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar la meta?','Eliminar Meta','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              
              title="Eliminar Meta"
              alt=""
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={
                () => {
                CustomDialog(               
                 <div>
                   <EditGoal row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar} setactualizacionmeta={props.setactualizacionmeta} setfiltrometas={props.setfiltrometas}></EditGoal>
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Meta",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              
              title="Editar Meta"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Ruta",
      accessor:"route"
    },
    {
      Header: "Fecha",
      accessor: (d)=>{
        
        var dia = d.month===1?"Enero":d.month===2?"Febrero":d.month===3?"Marzo":d.month===4?"Abril":
        d.month===5?"Mayo":d.month===6?"Junio":d.month===7?"Julio":d.month===8?"Agosto":d.month===9?"Septiembre":
        d.month===10?"Octubre":d.month===11?"Noviembre":d.month===12?"Diciembre":"Sin Fecha";

        return (dia + " de " + d.year)
      }
    },
    {
      Header:"Meta",
      accessor: (d) => {
        return d.goal
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.goal)
          : 
          Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.goal);
      },   
    }
    
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);
  return (
    <>
<div className="TableGoals">
  <div >         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={""}
    modalGeneral={false}
    modalT={modalT}
  ></MODAL_TABLE>
          <>
        
          <StickyTable>
        
          <div className="input-goal">
            <div className="grid-imp-1">
              <label>Fecha</label>
              < input 
                  type="month" 
                  value={my} 
                  onChange={(e)=>setmy(e.target.value)} 
              />
            </div>
            <div className="grid-imp-2">
              <button className="button" onClick={handleSubmit}>Buscar</button>  
            </div>   
          </div>   

            <TableContainer
              paginado={"Metas"}
              consultas={"Metas Consultadas"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>   
          </div>
        </div>
    </>
  );
}

export default TableGoal;
