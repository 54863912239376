//PAQUETERIAS
import { useEffect, useState, useMemo } from "react";
import { StickyTable} from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal"
//COMPONENTES
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { delete_models, get_models } from "../../services/assets/assets";
import TableContainer from "../../main_components/Table/TableContainer";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
//IMAGENES
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import OPERATIONS_MODEL from "./utilities/op_model";

function MODELS (props) {
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);

useEffect(() => {
  handlemodelos();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

const handlemodelos = async () => {
   
  setData([]);
  setopen(true);

  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_models(props.id);
          setData(d === null ? [] : d.data.models);
          setopen(false);
        }catch(err){
          setData([]);
          setopen(false);
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
}
  const columns = useMemo(
    () => [
      {
        Header:()=>(
          <div>
            <img
              id="AgregarModelo"
              src={CREATE}
              onClick={() => {
                CustomDialog(               
               <div>
                  <OPERATIONS_MODEL operation={"CREATE"} handlemodelos={handlemodelos} reconsultar={props.reconsultar} alertas={props.alertas} id={props.id}></OPERATIONS_MODEL>
               </div>,
             {
               className: "modalTItle",
               title:"Agregar Modelo",
               showCloseIcon: true,
               isCanClose:false,
             }
         );
       }}
              title="Agregar Nuevo Modelo"
              alt=""
            ></img>
          </div>
        ),
      id:"AccionesB",
      Cell: ({ row }) => {  

      const deletedetails = async () => {

        props.reconsultar(true);
        setData([]);
        setopen(true);
        try {
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try{
                const dt = await delete_models(props.id, row.original.id);
                console.log(dt);
                props.alertas("Modelo Relacionado a la Marca Eliminado", true);
                handlemodelos();
                props.reconsultar(false);
              }catch(err){
                console.log("error", err);
                props.alertas("No se pudo eliminar el Modelo relacionado a la Marca", false);
                handlemodelos();
                props.reconsultar(false);
              }
            }, 1000);
          }else{}
        } catch (err) {
          console.log(err);
        }
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el Modelo relacionado a la Marca?','Eliminar Modelo','Si','No');
                  if(result){
                    deletedetails();
                    }
                  }            
                }
              
              title="Eliminar Modelo"
              alt=""
            ></img>
            <img
              src={Edit}
              onClick={() => {
                  CustomDialog(               
                 <div>
                    <OPERATIONS_MODEL operation={"EDIT"} handlemodelos={handlemodelos} reconsultar={props.reconsultar} alertas={props.alertas} id={props.id} row={row}></OPERATIONS_MODEL> 
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Modelo",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              
              title="Editar Modelo"
              alt=""
            ></img>
          </div>
        );
      }
      },
      {
        Header:"Modelo",
        accessor: "name"
      },
      {
        Header:"Fecha Registro",
        accessor:"date_register"
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="subtablemodels">
      <div className="TableConsultarTodo2">  
            <StickyTable>
              <TableContainer
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                Gmodal={open}
                consultas={"Modelos Consultados"}
                paginado={"Modelos"}
                nametable={"Modelos"}
              />
            </StickyTable>
      </div>
    </div>
    
  );
};

export default MODELS;
