//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import NotificationSystem from 'react-notification-system';

//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import { get_price_list } from "../../services/inventory/inventory";
import CREATE_UPDATE from "./tools/create_update";
import { delete_price_list,get_price_list_products } from "../../services/inventory/inventory";

import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "./price_list.css"
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import VIEW from "../../imgComponents/lupa.png";
import CREATE from "../../imgComponents/create.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import PRODUCTS from "../products/products";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import SEARCH_PRODUCT from "../../imgComponents/icons/product.png"
import product_img from "../../imgComponents/icons/product.png"

const PRICE_LIST= () => {
  const [data, setData] = useState([]);
  const [menssage, setMenssage] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const dialog = useDialog();
  const notificationSystemRef = useRef();

  const handleSubmit = async (a,b) => {
    setmodalT(true);
    setData([]);
    setMenssage("Consultando lista de precios...");
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const dt = await get_price_list();
            setData(dt.data);

            
            if(a){
              handle_alert(b,a)
            }
            setmodalT(false);
          }catch(err){
            setData([]);
            setMenssage("");
          }
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    }
  };


  const [productsData, setProductsData] = useState([]);
  const [currentStoreIndex, setCurrentStoreIndex] = useState(0);

  // Función para hacer la petición GET y actualizar la lista de productos
  const handle_detail_warehouse = async (id) => {
    // ... tu lógica para realizar la petición GET
    const products = await get_price_list_products(id);

    // Agregar los productos a la lista productsData
    setProductsData((prevProductsData) => [
      ...prevProductsData,
      ...products.data.map((product) => ({
        id: id,
        name: data.find((store) => store.id === id)?.name,
        ...product,
      })),
    ]);

    // Incrementar el índice de la tienda actual
    setCurrentStoreIndex((prevIndex) => prevIndex + 1);
  };

  // Llamar a la función para obtener los datos de manera recursiva
  const fetchProductsRecursively = () => {
    if (currentStoreIndex < data.length) {
      handle_detail_warehouse(data[currentStoreIndex].id);
    }
  };

  // Iniciar la obtención de datos cuando el componente se monte
  useEffect(() => {
    fetchProductsRecursively();
  }, []);

  // Llamar a la función recursiva cuando se actualice el índice
  useEffect(() => {
    fetchProductsRecursively();
  }, [currentStoreIndex, data]);



  useEffect(() => {

    const groupedProducts = {};

    // Iteramos a través de los productos y los agrupamos por código
    productsData.forEach((product) => {
      if (!groupedProducts[product.code]) {
        // Si no existe el grupo para este código, lo creamos
        groupedProducts[product.code] = {
          code: product.code,
          product_name: product.product_name,
          store: [],
        };
      }

      // Agregamos el producto a su grupo correspondiente
      groupedProducts[product.code].store.push(product);
    });

    // Convertimos el objeto en un arreglo de grupos
    const groupedProductsArray = Object.values(groupedProducts);

    console.log(groupedProductsArray);

  }, [productsData]);

console.log(productsData);



  const handle_reconsult = (result) => {
    if (result?.confirm) {
      handleSubmit(result?.confirm, result.msj);
    } else if(result?.confirm === false){
      handle_alert(result.msj,result.confirm);
    }
  };

  const handle_alert = (msj, status) => {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);
  const columns = useMemo(
    () => [
    
      {
        Header: () => {
          return (
            <img
              alt=""
              title="Crear"
              src={CREATE}
              onClick={async () => {
                const result = await CustomDialog(
                  <CREATE_UPDATE price_list={""} method={"CREATE"}></CREATE_UPDATE>,
                  {
                    className: "modalTItle",
                    title: "Crear lista de precios",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
                // eslint-disable-next-line no-lone-blocks
                {
                  handle_reconsult(result);
                }
              }}
              className="centerText, taxes"
            ></img>
          );
        },
        id: "Acciones",

        Cell: ({ valor, row }) => {
       
          const handle_delete_producst_line = async (id) => {
            setmodalT(true);
            try{
              var rf_token = await refresh_token();
              if(rf_token===true){
                setTimeout(async() => {
                  try {
                    setMenssage("Eliminando lista de precios...");
                    await delete_price_list(id);
                    handle_alert("Lista de precios eliminada correctamente",true);
                    handleSubmit();
      
                  } catch (err) {
                    setmodalT(false);
                    setMenssage("");
                    console.log();
                    handle_alert(JSON.stringify(err.response.data),false);
                  }
                }, 1000);
              }else{}
            }catch(err){
              console.log(err);
            }
          };

          return (
            <div>
              <img
                alt=""
                title="Ver productos"
                src={VIEW}
                onClick={ () => {
                  sessionStorage.setItem("price_list_products",row.original.id);
                  window.location="/price_list_products";
                }}
                className="centerText, taxes"
              ></img>
              <img
                alt=""
                title="Actualizar"
                src={SAVE}
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE
                      price_list={row.original.name}
                      method={"UPDATE"}
                      id={row.original.id}
                    ></CREATE_UPDATE>,
                    {
                      className: "modalTItle",
                      title: "Actualizar lista de precios",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handle_reconsult(result);
                  }
                }}
                className="centerText, taxes"
              ></img>
            
              <img
                alt=""
                title="Eliminar lista de precios"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar esta lista de precios?",
                    "Eliminar lista de precios",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete_producst_line(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
        width:100,
      },

      {
        Header: "Nombre de la lista de precios",
        accessor: "name",
        width:600,
        minWidth: 600,
        maxWidth: 600,
        
      },
   
    ],
    []
  );

  return (
    <div >
      <div></div>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <BREADCRUMBS niveles={
            [
              {label: "LISTA DE PRECIOS", path:null},
            ]
          }
      ></BREADCRUMBS>
      <div className="TablePriceList">
        <div >
          <TableContainer
            columns={columns}
            data={data}
            exportar={true}
            function_ext={
              <img alt="" src={product_img} className="reportInvoice" title="Reporte de productos"
                onClick={()=>window.location="/PRICE_LIST_REPORT"}
              ></img>
            }
            nametable={"Lista de Precios"}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <PRODUCTS subtabla={true}/>
                </div>
              );
            }}
          />
        </div>
      </div>
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

    </div>
  );
};

export default PRICE_LIST;
