//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef} from "react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
//COMPONENTES
import AlertResponse from "../../../main_components/alerts/alertResponse";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import '../../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import Delete from "../../../imgComponents/borrar.png";
import CREATE from "../../../imgComponents/create.png";
import Edit from "../../../imgComponents/save.png";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import EditConceptC from "./tools/op_assigned_concepts";
import { SelectColumnFilter } from "../../../components_cfdi/filters";
import { delete_expense_concept_list, get_expense_concept_list } from "../../../services/expenses/expenses";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import TableContainer from "../../../main_components/Table/TableContainer";

const ASSIGNED_CONCEPTS= () => {
  console.log(sessionStorage.getItem("id_catalogo"));
  console.log(sessionStorage.getItem("nombre_catalogo"));

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);


  useEffect(()=>{
    handleSubmit();
  },[]);

  
function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n==true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}


//proceso de consultar el catalogo de conceptos de Gastos
const handleSubmit = async () => {
    console.log("entra a consultar los conceptos asignados al catalogo");

    if(entrada===0){
      entrada=entrada+1;
      setmenssage("Consultando Conceptos de Catálogo...");
      setmodalGeneral(true);
      setmodalT(true); 
    }else{
      setmodalT(true);
    }
    setopen(true);
    setData([]);
    try{
      var rf_token = await refresh_token();
      if(rf_token === true){
        setTimeout(async() => {
          try{
            const d = await get_expense_concept_list(sessionStorage.getItem("id_catalogo"));
            setData(d===null?[]:d.data.concepts);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }    
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
};
const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            alt=""
            id="crearRol"
            src={CREATE}
            onClick={async () => {
              const result = await CustomDialog(               
             <div>
                <EditConceptC operation={"CREATE"} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></EditConceptC>
             </div>,
           {
             className: "modalTItle",
             title:"Agregar Concepto",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Agregar Concepto"
            className=""
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ valor, row }) => {

      const DeleteSector = async()=>{  
      
      setmodalT(true);
      refresh_token ();
      setData([]);
      setopen(true);
    
      try{
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
              const d = await delete_expense_concept_list(sessionStorage.getItem("id_catalogo"), row.original.id);
              console.log(d);
              alertas("Concepto Eliminado con éxito",true);
              handleSubmit();
            }catch(err){
              console.log("error", err);
              setopen(false);
              alertas("Error, no se pudo eliminar el concepto", false);
              handleSubmit();
            }
          }, 1000);
        }else{}
      }catch(err){
          console.log(err);
      }
      }
        return (
          <div>
            <img
              alt=""
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el concepto?','Eliminar Concepto','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              
              title="Eliminar Concepto"
            ></img>
            
            <img
              alt=""
              src={Edit}
              onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                    <EditConceptC operation={"EDIT"} handleSubmit={handleSubmit} row={row} reconsultar={reconsultar} alertas={alertas}></EditConceptC>
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Concepto",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              
              title="Editar Concepto"
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Concepto",
      accessor:"concept"
    },
    {
      Filter: SelectColumnFilter,
      Header:"Estado",
      accessor:(d)=>{
        return d.enable==true?"Habilitado":"Deshabilitado"
      }
    },
    {
      Header: "Cuenta",
      accessor: "account"
    },
    {
      Header:"Fecha de Registro",
      accessor:"date_register"
    }
    
    ],
    []
);
  return (
    <>
        <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR CATALOGO DE CONCEPTOS", path: "/consult_expense_concept"},
                  {label: ` CATALOGO: ${sessionStorage.getItem("nombre_catalogo")}`, path:null }
                ]
              }
      ></BREADCRUMBS>

<div className="TableCatalogoGastosConceptos">
<NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
<MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
  <div >         
    
          <>
          <StickyTable>
            <TableContainer
              paginado={"Catálogo de Gastos"}
              consultas={"Catálogos de Gastos Consultados"}
              nametable={`CATALOGO: ${sessionStorage.getItem("nombre_catalogo")}`}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>   
           
      
          </div>
        </div>
    </>
  );
}

export default ASSIGNED_CONCEPTS;
