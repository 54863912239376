import axios from 'axios';
import config from "../../config";

const urlbp = `${config?.baseURLCONF}`;


////////////////////////// U S U A R I O S ////////////////////////////////////////////
//consultar todos los usuarios
export const get_users = async () => {
    const url = `${urlbp}/dev/users`;
    try {
        const response = await axios.get(url, {  headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

//ELIMINAR USUARIO
export const delete_user = async (id) => {
    const url = `${urlbp}/dev/users/${id}`;
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  //Crear USUARIO
  export const post_user = async (data) => {
    const url = `${urlbp}/dev/users`;
    try {
      const response = await axios.post(url, data, {
        headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  //actualizar USUARIO
  export const put_user = async (id, data) => {
    const url = `${urlbp}/dev/users/${id}`;
    try {
      const response = await axios.put(url, data, {
        headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };





/////////////////// R O L E S ////////////////////////////////////////////////////

//consultar todos los roles
export const get_roles = async () => {
    const url = `${urlbp}/dev/roles`;
    try {
        const response = await axios.get(url, {  headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

//ELIMINAR ROLE
export const delete_roles = async (id) => {
    const url = `${urlbp}/dev/roles/${id}`;
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  //Crear ROLE
  export const post_roles = async (data) => {
    const url = `${urlbp}/dev/roles`;
    try {
      const response = await axios.post(url, data, {
        headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  //actualizar ROLE
  export const put_roles = async (id, data) => {
    const url = `${urlbp}/dev/roles/${id}`;
    try {
      const response = await axios.put(url, data, {
        headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

//////////////// A L M A C E N ////////////////////////

//consultar todos los almacenes
export const get_warehouse = async () => {
    const url = `${urlbp}/dev/warehouse`;
    try {
        const response = await axios.get(url, {  headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

//ELIMINAR ALMACEN
export const delete_warehouse = async (id) => {
    const url = `${urlbp}/dev/warehouse/${id}`;
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  //Crear ALMACEN
  export const post_warehouse = async (data) => {
    const url = `${urlbp}/dev/warehouse`;
    try {
      const response = await axios.post(url, data, {
        headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  //actualizar ALMACEN
  export const put_warehouse = async (id, data) => {
    const url = `${urlbp}/dev/warehouse/${id}`;
    try {
      const response = await axios.put(url, data, {
        headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
///////////////////// R U T A S ///////////////////////////7
//consultar todas las rutas
export const get_route = async () => {
  const url = `${urlbp}/dev/route`;
  try {
      const response = await axios.get(url, {  headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          }});
      return Promise.resolve(response);
  } catch (error) {
      return Promise.reject(error);
  }
};

//ELIMINAR ALMACEN
export const delete_route = async (id) => {
  const url = `${urlbp}/dev/route/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Crear ALMACEN
export const post_route = async (data) => {
  const url = `${urlbp}/dev/route`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//actualizar ALMACEN
export const put_route = async (id, data) => {
  const url = `${urlbp}/dev/route/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

////////////////////////////////////////////////////////////


//roles de una compañia
export const get_company_roles = async () => {
    const url = `${urlbp}/dev/company/roles`;
    try {
        const response = await axios.get(url, {  headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const get_route_users = async () => {
  const url = `${urlbp}/dev/route/users`;
  try {
      const response = await axios.get(url, {  headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          }});
      return Promise.resolve(response);
  } catch (error) {
      return Promise.reject(error);
  }
};

export const get_types_of_sales = async () => {
  const url = `${urlbp}/dev/route/types-of-sales`;
  try {
      const response = await axios.get(url, {  headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          }});
      return Promise.resolve(response);
  } catch (error) {
      return Promise.reject(error);
  }
};

export const get_route_warehouse = async () => {
  const url = `${urlbp}/dev/route/warehouse`;
  try {
      const response = await axios.get(url, {  headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          }});
      return Promise.resolve(response);
  } catch (error) {
      return Promise.reject(error);
  }
};

///////////////////// E D I T   C O M P A N Y /////////////////
export const get_company_address = async () => {
  const url = `${urlbp}/dev/company/address`;
  try {
      const response = await axios.get(url, {  headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          }});
      return Promise.resolve(response);
  } catch (error) {
      return Promise.reject(error);
  }
};
//consultar informacion de company
export const get_company = async () => {
  const url = `${urlbp}/dev/company`;
  try {
      const response = await axios.get(url, {  headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          }});
      return Promise.resolve(response);
  } catch (error) {
      return Promise.reject(error);
  }
};

//actualizar informacion de compañia
export const put_company = async (data) => {
  const url = `${urlbp}/dev/company`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//actualizar direccion de compañia
export const put_company_address = async (data) => {
  const url = `${urlbp}/dev/company/address`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};