//Paqueterias
import { useState, useEffect, useMemo } from "react";
//Componentes
import TableContainer from "../../../main_components/Table/TableContainer";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { get_detil_warehouse } from "../../../services/inventory/inventory";
import { ValidationTextField } from "../../../main_components/lable/labe";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
//Estilos
import "../tools/movements.css";

const CONSULT_DETAIL_WAREHOUSE = (props) => {
  const [modalT] = useState(false);
  const [vendor, setvendor] = useState([]);
  const [data, setData] = useState([]);

  const handle_detail_warehouse = async () => {
    try {
      const isvaild = await refresh_token();
      if(isvaild){
        setTimeout( async () => {
          try{
            const dt = await get_detil_warehouse(props.id);
            setData(dt.data);
          }catch(err){
            setData([]);
          }
        },1500)
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  useEffect(() => {
    handle_detail_warehouse();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Partida",
        accessor: "row",
      },
      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Producto",
        accessor: "description",
      },
      {
        Header: "Cantidad",
        accessor: "quantity",
      },
      {
        Header: "Tipo",
        accessor: (d) => {
          return props.reference;
        },
      },
    ],
    []
  );

  return (
    <div>
      <div>
        <MODAL_TABLE
          open={true}
          message={""}
          modalGeneral={false}
          modalT={modalT}
        ></MODAL_TABLE>
      </div>
      <div>
        <div className="containerDetail">
          <div className="dt-1">
            <ValidationTextField
              label="Fecha"
              variant="outlined"
              sx={{ m: 2, width: "90%" }}
              value={props.date.slice(0, -7)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="dt-2">
            <ValidationTextField
              label="Tipo de movimiento"
              variant="outlined"
              sx={{ m: 2, width: "90%" }}
              value={props.movement_type}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="dt-3">
            <ValidationTextField
              label="Almacen"
              variant="outlined"
              sx={{ m: 2, width: "90%" }}
              value={props.warehouse_name}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="dt-4">
            <ValidationTextField
              label="Partidas"
              variant="outlined"
              sx={{ m: 2, width: "90%" }}
              value={props.rows}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="dt-5">
            <ValidationTextField
              label="Nota"
              variant="outlined"
              sx={{ m: 2, width: "90%" }}      
              value={props.note}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="dt-6">
            <ValidationTextField
              label="Venta de referencia"
              variant="outlined"
              sx={{ m: 2, width: "90%" }}
              value={props.sale}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="dt-7">
            <ValidationTextField
              label="Venta de devolución"
              variant="outlined"
              sx={{ m: 2, width: "90%" }}
              
              value={props.returns}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="dt-8">
            <ValidationTextField
              label="Venta de devolución"
              variant="outlined"
              sx={{ m: 2, width: "90%" }}
              
              value={props.returns}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
          <div
            className="TableReport TableCutBoxx"
            style={{ overflowX: "auto", overflowY: "auto" }}
          >
            <TableContainer
              exportEx={true}
              exportar={true}
              columns={columns}
              paginado={"Productos"}
              consultas={"Productos Consultados"}
              title={"Movimiento emitido por: "+props?.fullusername	}
              hpdf={0}
              data={data}
              style={{ overflowx: 10 }}


              GrantotalCabeceras={["Partida", "Código"]}
              Grantotal={true}
       
              v={true}
              sinval={true}
            />
        </div>
      </div>
    </div>
  );
};

export default CONSULT_DETAIL_WAREHOUSE;
