//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm, Alert } from "react-st-modal";
import NotificationSystem from "react-notification-system";

//COMPONENTES
import { SelectColumnFilter } from "../../components_cfdi/filters";
import TableContainer from "../../main_components/Table/TableContainer";
import SubTable from "./tools/subTable";
import CREATE_UPDATE from "./tools/set_bussines_p";
import Update_bussines_p from "../../form_business_partner/update_bussines_p.jsx";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { pagination } from "../../main_components/pagination/pagination";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import datacfdi from "../../dataComponets/usoCFDI.json";
import datametodopago from "../../dataComponets/metodo_pago.json";
import dataregimenfiscal from "../../dataComponets/regimen.json";
import { MultiSelectColumnFilter } from "../../components_cfdi/filters";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { delete_business_partner } from "../../services/businesspartners/businesspartners";
//ESTILOS
// import "../components_cfdi/estilo.css";
import "../tableclientes.css";
// import "../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import CODEQR from "../../imgComponents/codigoqr.png";

const TableUpdateBusnes = () => {
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [isLoding, setIsLoding] = useState(true);
  const [menssage, setmenssage] = useState("Consultando socios de negocios...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const handleSubmit = async (msjAler) => {
    setmodalT(true);
    setIsLoding(true);
    setData([]);
    try {
      var refreshprueba = await refresh_token();
      console.log(refreshprueba);
    } catch (err) {
      alert("refresh_token");
    }
    try {
      var prueba = await pagination(3, "bp", null);
      console.log(prueba);
      setmodalGeneral(false);
      setmodalT(false);
      setData(prueba === null ? [] : prueba);
      setIsLoding(false);
      if (msjAler?.length > 1) {
        notificationSystemRef.current.addNotification({
          message: <AlertResponse msj={msjAler} view={true}></AlertResponse>,
          level: "success",
          position: "br",
          autoDismiss: 10,
        });
      }
    } catch (err) {
      alert("entra en el error");
      var errroM = err?.response?.data?.errorMessage;
      setData([]);
      notificationSystemRef.current.addNotification({
        message: (
          <AlertResponse
            msj={JSON.stringify(errroM)}
            view={false}
          ></AlertResponse>
        ),
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
      setmodalT(false);
    }
  };

  const handle_delete = async (id) => {
    setmodalT(true);

    try {
      await delete_business_partner(id);
      setmodalT(false);
      handleReconsulta(true,"Socio de negocios eliminado correctamente")

    } catch (err) {
      notificationSystemRef.current.addNotification({
        message: (
          <AlertResponse
            msj={JSON.stringify(err?.response?.data)} 
            view={false}
          ></AlertResponse>
        ),
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
      setmodalT(false);

    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handleSubmit(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };
  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <>
              <img
                title="Crear socio de negocio "
                src={CREATE}
                alt=""
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE 
                    actualiza={false} 
                    method={"CREATE"}
                    supplierdata={false}
                    code={""}
                    business_partner={""}
                    tax_regime={""}
                    name={""}
                    first_last_name={""}
                    second_last_name={""}
                    balance={0}
                    credit_days={0}
                    business_partner_type={"CLIENTE"}
                    capital_regime={""}
                    partner_type={""}
                    person_type={""}
                    cfdi_use={""}
                    phone={""}
                    metodo_pago={""}
                    paymethod={""}
                    allow_invoicing={""}
                    street={""}
                    outdoor_number={""}
                    inside_number={""}
                    neighborhood={""}
                    zip_code={""}
                    city={""}
                    state={""}
                    email={""}
                    country={""}
                    reference={""}
                    address_type={""}
                    subsidiary={""}
                    tax_residence={""}
                    rfc={""}
                    num_reg_id_trib={""}
                    town={""}
                    partner_type_data={"PROPIO"}
                    uso_cfdi_data={"G01"}
                    state_data={"VERACRUZ DE IGNACIO DE LA LLAVE"}
                    address_type_data={"FISCAL"}
                    tax_regime_data={"601"}
                    subsidiary_data={"MATRIZ"}
                    metodo_pago_data={"PUE"}
                    account_data={""}
                    id_accounting_account_fk={""}
                    ></CREATE_UPDATE>,
                    {
                      className: "modalSetBussnes",
                      title: "Crear socio de negocio",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(
                      result,
                      "Socio de negocio creado correctamente"
                    );
                  }
                }}
              ></img>
            </>
          );
        },
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("socioHabilitado", row.original.id);
          }

          return (
            <a {...row.getToggleRowExpandedProps()}>
              {row?.isExpanded ? (
                <span>🔼</span>
              ) : (
                <span onClick={guardardato}>🔽</span>
              )}
            </a>
          );
        },
      },
      {
        Header: () => {
          return (
            <>
              <p style={{ color: "white" }}>Acciones</p>
              <img
                alt=""
                title="Actualizar socio de negocios por código QR"
                src={CODEQR}
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <Update_bussines_p></Update_bussines_p>,
                    {
                      className: "modalSetBussnes",
                      title: "Actualizar socio de negocios",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(
                      result,
                      "Exito socio de negocios creado/actualizado correctamente"
                    );
                  }
                }}
              ></img>
            </>
          );
        },
        id: "Acciones",

        Cell: ({ row }) => {

          return (
            <div>
              <img
                alt=""
                title="Actualizar socio de negocios"
                src={SAVE}
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE 
                    method={"UPDATE"}
                    actualiza={false} 
                    supplierdata={false}
                    code={row.original.code}
                    business_partner={row.original.business_partner}
                    tax_regime={row.original.tax_regime}
                    name={row.original.name}
                    rfc={row.original.rfc}
                    first_last_name={row.original.first_last_name}
                    second_last_name={row.original.second_last_name}
                    balance={row.original.balance}
                    credit_days={row.original.credit_days}
                    business_partner_type={row.original.business_partner_type}
                    capital_regime={row.original.capital_regime}
                    partner_type={row.original.partner_type}
                    person_type={row.original.person_type}
                    cfdi_use={row.original.cfdi_use}
                    phone={row.original.phone}
                    email={row.original.email}
                    metodo_pago={row.original.metodo_pago}
                    paymethod={row.original.paymethod}
                    allow_invoicing={row.original.allow_invoicing}
                    street={row.original.street}
                    outdoor_number={row.original.outdoor_number}
                    inside_number={row.original.neighborhood}
                    neighborhood={row.original.neighborhood}
                    zip_code={row.original.zip_code}
                    city={row.original.city}
                    state={row.original.state}
                    country={row.original.country}
                    reference={row.original.reference}
                    address_type={row.original.address_type}
                    subsidiary={row.original.subsidiary}
                    tax_residence={row.original.tax_residence===null?"":row.original.tax_residence}
                    num_reg_id_trib={row.original.num_reg_id_trib}
                    town={row.original.town}
                    partner_type_data={row.original.partner_type}
                    uso_cfdi_data={row.original.uso_cfdi}
                    state_data={row.original.state}
                    address_type_data={row.original.address_type}
                    tax_regime_data={row.original.tax_regime}
                    subsidiary_data={row.original.subsidiary}
                    metodo_pago_data={row.original.metodo_pago}
                    account_number={row.original.account_number}
                    id={row.original.id}
                    account_data={row.original.name_accounting_account+"-"+row.original.code_accounting_account}
                    id_accounting_account_fk={row.original.id_accounting_account_fk}
                    ></CREATE_UPDATE>,
                    {
                      className: "modalSetBussnes",
                      title: "Actualizar socio de negocio",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(
                      result,
                      "Socio de negocios actualizado correctamente"
                    );
                  }
                }}
              ></img>

              <img
                alt=""
                src={DELETE}
                className=""
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este Socio de Negocios?",
                    "Eliminar socio de negocios",
                    "Si",
                    "No"
                  );

                  if (result) {
                    handle_delete(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Código",
        accessor: "code",
        // Filter: MultiSelectColumnFilter,
        // filter: "includes",
      },
      {
        Header: "Socio de Negocios",
        accessor: "business_partner",
      },
      {
        Header: "RFC",
        accessor: "rfc",
      },
      {
        Header: "Tipo de persona",
        accessor: "person_type",
      },
      {
        Header: "E-MAIL",
        Filter: SelectColumnFilter,
        accessor: (d) => {
          return d.email?.length > 1 ? "SI" : "NO";
        },
      },
      {
        Header: "Método de pago",
        accessor: "paymethod",
        Filter: SelectColumnFilter,
        Cell: ({ value }) => (
          <div
            title={
              datametodopago.filter((item) => item.value === value)[0]?.label
            }
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Uso_CFDI",
        accessor: "cfdi_use",
        Cell: ({ value }) => (
          <div
            title={datacfdi.filter((item) => item.value === value)[0]?.label}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Código Postal",
        accessor: "zip_code",
      },
      {
        Header: "Regimen Fiscal",
        accessor: "tax_regime",
        Cell: ({ value }) => (
          <div
            title={
              dataregimenfiscal.filter((item) => item.value === value)[0]?.label
            }
          >
            {value}
          </div>
        ),
      },
     
    ],
    []
  );

  return (
    <div>
      <BREADCRUMBS
        niveles={[{ label: "CONSULTAR SOCIOS DE NEGOCIOS", path: null }]}
      ></BREADCRUMBS>

      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <div >
        <div >
          <div className="containerBussnesP">
            <div className="expen-1 color-1" style={{ paddingTop: "10px" }}>
              <select
                id="canceladas-si-no"
                onChange={(event) => setCancel(event.target.value)}
              >
                <option>Clientes Habilitados/Deshabilitados</option>
                <option value={true}>Habilitados</option>
                <option value={false}>Deshabilitados</option>
              </select>
            </div>

            <div className="expen-2 color-1">
              <button className="button" onClick={handleSubmit} type="button">
                Buscar
              </button>
            </div>
          </div>

          <div className="TableCOnsultaClientes">
            <TableContainer
              exportar={true}
              columns={columns}
              data={data}
              nametable={"Tabla Socios de Negocios"}
              Gmodal={modalT}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableUpdateBusnes;
