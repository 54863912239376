import TableContainer from "../../movements/table_movements";
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const DETAIL_SALE = (props) => {
  const [lat, setlat] = useState(props.lat);
  const [long, setlong] = useState(props.lng);

  const [map, setmap] = useState(false);

  const columns3 = useMemo(
    () => [
      {
        Header: "Partida",
        accessor: "row",
      },
      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Producto",
        accessor: "product_name",
      },
      {
        Header: "Precio",
        accessor: "price",
      },
      {
        Header: "Cantidad",
        accessor: "quantity",
      },
      {
        Header: "Subtotal",
        accessor: "subtotal",
      },
      {
        Header: "Descuento",
        accessor: "discount",
      },
      {
        Header: "Impuestos",
        accessor: "tax",
      },
      {
        Header: "Total",
        accessor: "total",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  console.log(props.lat);
  console.log(props.lng);
  console.log("lat:" + props.lat + ", log:" + props.lng);

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={17}
        defaultCenter={{ lat: Number(lat), lng: Number(long) }}
      >
        <Marker
          position={{ lat: Number(lat), lng: Number(long) }}
          icon={{
            fillOpacity: 1,
            rotation: 0,
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 2,
            animation: window.google.maps.Animation.DROP,
            draggable: true,
          }}
        />
      </GoogleMap>
    ))
  );

  return (
    <div>
      <div className="price-load-dta-detail">
        <div className="expen-5n">
          <div class="ticket">
            <h2>
              {props.sale_retun}:{props.ticket}
              <br />
              {props.name_pos}
            </h2>
            <p
              style={{ display: props.sale_retun === "Venta" ? "" : "none" }}
              className="centrado detalles"
            >
              <span>Subtotal:</span> ${props.subtotal} <br />
              <span>Descuento:</span> ${props.discount} <br />
              <span>Impuestos:</span> ${props.tax} <br />
              <span>Total:</span> ${props.total} <br />
              <span>Deuda Pendiente de pago:</span> ${props.debt} <br />
              <span>Deuda pagada:</span> ${props.payed} <br />
              <span>Método de pago:</span> {props.payment_method} <br />
              <span>Congelador:</span> {props.scan}
            </p>
            <p
              style={{
                display: props.sale_retun === "Devolución" ? "" : "none",
              }}
              className="centrado detalles"
            >
              <span>Subtotal:</span> ${props.subtotal} <br />
              <span>Descuento:</span> ${props.discount} <br />
              <span>Impuestos:</span> ${props.tax} <br />
              <span>Total:</span> ${props.total} <br />
            </p>
            
            <h2>Notas</h2>
            <p class="centrado detalles">
              <span>Nota vendedor:</span> {props.note} <br />
            </p>
            <h2>Datos para facturar</h2>
            <p class="centrado detalles">
              <span>Razón social:</span> {props.name_business_partner} <br />
              <span>RFC:</span> {props.rfc_business_partner} <br />
              <span>Código del socio de Negocios:</span> {props.code_pos} <br />
            </p>
          </div>
        </div>

        <div className="expen-6 map-product-load">
            <MapWithAMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div style={{ height: `300px`, width: "95%" }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
        </div>
      </div>

      <div
        className="TableProducLoad"
        style={{ display: map === true ? "none" : "" }}
      >
        <StickyTable>
          <TableContainer
            paginado={"Detalles"}
            consultas={""}
            Grantotal={true}
            exportar={true}
            columns={columns3}
            data={props?.details === undefined ? [] : props?.details}
            style={{ overflowx: 10 }}
            Gmodal={props?.open}
          />
        </StickyTable>
      </div>
    </div>
  );
};

export default DETAIL_SALE;
