//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, Confirm } from "react-st-modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
//COMPONENTES

import TableContainer_data from "../table_movements";
import { get_warehouse_existence } from "../../../services/inventory/inventory";
import { create_warehouse_movements } from "../../../services/inventory/inventory";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { get_warehouse } from "../../../services/inventory/inventory";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


//ESTILOS
import "./movements.css";
//IMAGENES/ICONOS
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";

export const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});
const WAREHOUSE_MOVEMENTS_LIST = () => {

  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity_data, setQuantity_data] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');



  const [dataTable, setDataTable] = useState([]);
  const [dataTableOriginal, setdataTableOriginal] = useState([]);

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [reference, setreference] = useState("");
  const [note, setnote] = useState("");
  const [validate_reference, setvalidate_reference] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [originalData] = useState(dataTable);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [quantity, setquantity] = useState(0);
  const [origin, setorigin] = useState(0);
  const [destination, setdestination] = useState(0);
  const [warehouse, setwarehouse] = useState(0);
  const [almacenO, setalmacenO] = useState(0);
  const [almacenD, setalmacenD] = useState(0);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [tableData_data, setTableData_data] = useState([]);

  const handleProductChange = (event, newValue) => {
    setSelectedProduct(newValue);
  };

  const handleQuantityChange = (event) => {
    const quantity = event.target.value;
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      quantity: quantity,
    }));
  };

  const handleAddToTable = () => {
    if (selectedProduct) {
      setTableData_data((prevData) => [...prevData, selectedProduct]);
      setSelectedProduct(null);
    }
  };

  const handleRemoveFromTable = (productToRemove) => {
    setTableData_data((prevData) =>
      prevData.filter((product) => product !== productToRemove)
    );
  };




  const handleSubmit = async (event) => {
    event?.preventDefault();
    setmodalT(true);
    setMenssage("Productos...");
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt = await get_warehouse_existence(
              sessionStorage.getItem("origin")
            );
            const dt2 = await get_warehouse_existence(
              sessionStorage.getItem("destination")
            );
            setData(dt.data);
            setData2(dt2.data);
            const ndatadestination = dt2.data.map((e) => ({
              destination: e.quantity,
            }));
            if (sessionStorage.getItem("origin") === "-1") {
              const ndata = dt2.data.map((e, index) => ({
                code: e.code,
                id: e.id_products_fk,
                description: e.description,
                origin: 0,
                quantity: 0,
                destination: e.quantity,
              }));
      
              console.log(ndata);
              setDataTable(ndata);
              setdataTableOriginal(ndata)
            } else {
              const ndata = dt.data.map((e, index) => ({
                code: e.code,
                id: e.id_products_fk,
                description: e.description,
                origin: e.quantity,
                quantity: 0,
                destination: ndatadestination[index].destination,
              }));
              setDataTable(ndata);
              setdataTableOriginal(ndata)
      
            }
      
            setmodalT(false);
          } catch (err) {
            setDataTable([]);
            setMenssage("");
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const handle_warehouse = async () => {
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt= await get_warehouse();
            const data=dt.data;
              var dtAdd = {
                id: -1,
                warehouse_name: "ENTRADA",
              };
              var dtAdd2 = {
                id: -2,
                warehouse_name: "SELECCIONA UN ALMACEN",
              };
      
              data.push(dtAdd);
              data.push(dtAdd2);
              console.log(data)
              const origenWarehose=data.filter((d)=>d.id===Number(sessionStorage.getItem("origin")));
              const destinationWarehose=data.filter((d)=>d.id===Number(sessionStorage.getItem("destination")));
              setalmacenO(origenWarehose[0]?.warehouse_name)
              setalmacenD(destinationWarehose[0]?.warehouse_name);
              setwarehouse(data);
          } catch (error) {
            setwarehouse([]);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };


  const handle_move_all_product = () => {
  

    const ndata = data.map((e, index) => ({
      code: e.code,
      description: e.description,
      origin: e.quantity,
      id:e.id_products_fk,
      quantity: e.quantity,
      destination: data2[index]?.quantity,
    }));

    console.log(ndata);
    setDataTable(ndata);
    var ntest = ndata.filter((d) => d.quantity !== "");
              console.log(ntest);
              var cantidad=  ntest
              .map((a) => Number(a.quantity))
              .reduce((previous, current) => {
                return previous + current;
              }, 0)
    setquantity(cantidad);
  };
  

  const handle_create_movement = async () => {
    const ndt = dataTable.filter(d => d.quantity && d.quantity !== 0).map((d) => {
      console.log(d)
      var dt={
        id:d.id,
        quantity:d.quantity
      }
    return dt} );
    var env_data = {
      origin: sessionStorage.getItem("origin"),
      destination: sessionStorage.getItem("destination"),
      note: note,
      reference: reference,
      movement: ndt,
    };

    console.log(env_data);
      if(ndt.length>0){
        setmodalT(true);
        try{
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try {
                await create_warehouse_movements(env_data);
                setmodalT(false);
                window.location="/movements"
              } catch (err) {
                setmodalT(false);
                alert(JSON.stringify(err.response))
              }
            }, 1000);
          }else{}
        }catch(err){
          console.log(err);
        }
      }else{
        alert("Realiza almenos un movimiento");
      }
  };
  useEffect(() => {
    handleSubmit();
    handle_warehouse();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: "",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },

      {
        id: "code",
        Header: "Código",
        accessor: "code",

      },
      {
        id: "description",
        Header: "Descripción",
        accessor: "description",

      },
      {
        Header: "Cantidad",
        
        Cell:(row)=>{
          const [quantity_data, setquantity_data] = useState(row?.row?.original?.quantity);
          const [activar, setactivar] = useState(false);
          console.log(row.row.original)
          console.log(dataTable)
          console.log(quantity_data);
          const editcell=(e)=>{
            setactivar(true);
            if(sessionStorage.getItem("origin")==="-1"){

              dataTable[row.row.index] = {
                code: row.row.original.code,
                description: row.row.original.description,
                origin: row.row.original.quantity,
                quantity: e.target.value,
                id: row.row.original.id,
                destination: row.row.original.destination,
              };
              console.log(dataTable);
              console.log(e.target.value);
              var ntest = dataTable.filter((d) => d.quantity !== "");
              console.log(ntest);
              var cantidad=  ntest
              .map((a) => Number(a.quantity))
              .reduce((previous, current) => {
                return previous + current;
              }, 0)
              console.log(cantidad);

              setquantity(cantidad);
              var dt=dataTable;
              setquantity_data(e.target.value)
              setDataTable(dt);

            }else{
        
            
            if(e.target.value>0){
              dataTable[row.row.index] = {
                code: row.row.original.code,
                description: row.row.original.description,
                origin: row.row.original.quantity,
                id: row.row.original.id,
                quantity: validarDato(e.target.value,row.row.original.origin),
                destination: row.row.original.destination,
              };
              console.log(dataTable);
              console.log(e.target.value);
              var ntest = dataTable.filter((d) => d.quantity !== "");
              console.log(ntest);
              var cantidad=  ntest
              .map((a) => Number(a.quantity))
              .reduce((previous, current) => {
                return previous + current;
              }, 0)
              console.log(cantidad)
              setquantity(cantidad);
              var ndt=dataTable;

              setquantity_data(validarDato(e.target.value,row.row.original.origin));
              setDataTable(ndt);

            } else{
              console.log("entra dato")
              dataTable[row.row.index] = {
                code: row.row.original.code,
                description: row.row.original.description,
                origin: row.row.original.quantity,
                id: row.row.original.id,
                quantity: validarDato(0,row.row.original.origin),
                destination: row.row.original.destination,
              };
              console.log(dataTable);
              console.log(0);
              var ntest = dataTable.filter((d) => d.quantity !== "");
              console.log(row.row.original.origin);
              var cantidad=  ntest
              .map((a) => Number(a.quantity))
              .reduce((previous, current) => {
                return previous + current;
              }, 0)
              console.log(cantidad)
              setquantity(cantidad);
              var ndt=dataTable;

              setquantity_data(validarDato(0,row.row.original.origin));
              setDataTable(ndt);
            }
          }
        
          function validarDato(datoUsuario, datoOrigen) {
            if (datoUsuario <= datoOrigen) {
              return Number(datoUsuario);
            } else {
              const nuevoDato = datoUsuario.slice(0, -1);
              return validarDato(nuevoDato, datoOrigen);
            }
          }      
          }
          
          

          return (
            <div>
              <input value={dataTable[row.row.index].quantity===""?quantity_data:dataTable[row.row.index].quantity} onChange={(e)=>editcell(e)}></input>
            </div>
          );
        },
       

      },
      {
        id: "Origen",
        Header: "Origen",
        accessor: "origin",
    
      },
      {
        id: "destination",
        Header: "Destino",
        accessor: "destination",
        

      },
    ],
    [dataTable]
  );
  console.log(quantity)
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setDataTable((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          console.log(columnId);
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  useEffect(() => {
    console.log(dataTable)
    setSkipPageReset(false);
    var ntest = dataTable.filter((d) => d.quantity !== "");
    console.log(ntest);

    setorigin(
      dataTable
        .map((a) => Number(a.origin))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    setdestination(
      dataTable
        .map((a) => Number(a.destination))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    
  }, [dataTable]);



  useEffect(() => {
    setDataTable(dataTable);
  }, [dataTable]);
  const resetData = () => dataTable(originalData);

  return (
    <div >

<BREADCRUMBS niveles={
       [
        {label: "MOVIMIENTO DE PRODUCTOS", path: "/movements"},
        {label: "GENERAR MOVIMIENTO POR TABLA", path: "/werehouse_movements"},
        {label: "GENERAR MOVIMIENTO POR LISTA", path: null},
      ]
          }
      ></BREADCRUMBS>

      <div>
        <h3>MOVIMIENTO DEL ALMACÉN: {almacenO} AL ALMACÉN:  {almacenD}</h3>
      </div>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>

     
      <div>
      <h1>Productos</h1>
      <Autocomplete
        id="product-search"
        options={data}
        getOptionLabel={(option) => `${option.code} - ${option.description}`}
        value={selectedProduct}
        onChange={handleProductChange}
        renderInput={(params) => <TextField {...params} label="Buscar producto" />}
      />
      <TextField
        id="quantity"
        label="Cantidad"
        type="number"
        value={selectedProduct ? selectedProduct.quantity : ''}
        onChange={handleQuantityChange}
      />
      <Button variant="contained" onClick={handleAddToTable}>
        Agregar
      </Button>

      <h2>Tabla de productos</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {tableData_data.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{product.code}</TableCell>
                <TableCell>{product.description}</TableCell>
                <TableCell>{product.quantity}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleRemoveFromTable(product)}
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
     
      <div className="containerMovementsProduct2">
        <div className="pdc-1 color-1">
          <ValidationTextField
            label="Referencia"
            placeholder="Referencia"
            variant="outlined"
            sx={{ m: 2, width: "90%" }}
            defaultValue="Success"
            name="capital_regime"
            value={reference}
            onChange={(e) => {
              const value = e.target.value;
              if (typeof value === "string") {
                setreference(value.toUpperCase());
              } else {
                setreference(value);
              }
            }}
            
            InputLabelProps={{
              shrink: true,
            }}
          />
          <br />
         
        </div>

        <div className="pdc-2 color-2">
          <ValidationTextField
            label="Nota"
            placeholder="Nota"
            variant="outlined"
            sx={{ m: 2, width: "90%" }}
            defaultValue="Success"
            name="capital_regime"
            value={note}
            onChange={(e) => {
              const value = e.target.value;
              if (typeof value === "string") {
                setnote(value.toUpperCase());
              } else {
                setnote(value);
              }
            }}
           
            InputLabelProps={{
              shrink: true,
            }}
          />
          <br />
          
        </div>

        <div className="pdc-3">
          <Button
            sx={{ width: "100%" }}
            style={{
              display: sessionStorage.getItem("origin") === "-1" ? "none" : "",
            }}
            variant="contained"
            onClick={() => handle_move_all_product()}
          >
            Mover Todo el Producto
          </Button>
        </div>
        <div className="pdc-4">
          <Button
            sx={{ width: "100%" }}
           
            variant="contained"
            onClick={async () => {
            
                const result = await Confirm(
                  "¿Esta usted seguro de relizar este movimiento?",
                  "Realizar movimiento",
                  "Si",
                  "No"
                );
                if (result) {
                  handle_create_movement();
                } else {
                }
            
            }}
          >
            Crear Movimiento 
          </Button>
        </div>
      </div>

      <div className="TableTaxes">
       
      </div>
    </div>
  );
};

export default WAREHOUSE_MOVEMENTS_LIST;
