import { Component } from "react";
import { AuthState } from "@aws-amplify/ui-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Get_bussinesP from "../pages/business_partner/getBusiness_parther";
import CfdiTable from "../pages/consult_invoice/consult_invoces";
import GENERATE_INVOICE from "../components_cfdi/invoice/generate_invoice/generate_invoice";
import BookData from "../nav/Data.json";
import BookData02 from "../nav/Data02.json";
import BookData03 from "../nav/Data03.json";
import BookData04 from "../nav/Data04.json";
import UpdateBusnes from "../pages/business_partner/editBusnes";
import "../form_business_partner/resp.css";
import "../Login/style.css";
import SetCarataportev4 from "../pages/consignment_note/Cartaportev4";
import PAGO from "../components_cfdi/invoice/payment/pago";
import SetValidador from "../pages/providers/Validador";
import SetfacturaPPD from "../pages/consult_invoice/consult_invocesPPD";
import SetConsultarFacturasProveedores from "../pages/providers/ConsultarFacturasProvedores";
import SetConsultaTaxes from "../pages/consult_taxes/consult_taxes";
import SetTableBonuses from "../pages/unilever/reportBonification";
import SetEditAddBonuses from "../pages/unilever/editAddBonuses";
import SetConsulConfigureTaxes from "../pages/consult_taxes/consult_configure_tax";
import CONSUL_CONCEPT from "../components_cfdi/invoice/concepts/consult_concept";
import SetReportRoutes from "../pages/routes/reportRoutes";
import NewDashboard from "../pages/newDasboard/dashboard";
import SetSectors from "../pages/business_partner/sectors/setSectors";
import SetDiscounts from "../pages/business_partner/discounts/setDiscounts";
import SetPromotions from "../pages/business_partner/promotions/setPromotions";
import setPointsOfSale from "../pages/business_partner/pointsofsale/setPointsOfSale";
import SetRegisterGeneral from "../pages/business_partner/registerGeneral/setRegisterGeneral";
import SetBanc from "../pages/banco/setBanc";
import Consult_cut_box_sale from "../component_bank/cashclosing/cashclosing/cahsclosing_sale/consult_cashclosing_sale";
import TableRoles from "../componentConfiguration/componentRoles/TableRoles";
import EditCompany from "../componentConfiguration/componentCompany/EditCompany";
import TableAccountingAccount from "../componentaccount/TableAccountingAccount";
import TableUsers from "../componentConfiguration/componentUsers/TableUsers";
import TableExpenseConcept from "../components_Expenses/ComponentExpenseConcept/TableExpenseConcept";
import Consult_drivers from "../componentHumanResources/drivers/consult_drivers";
import PAID from "../component_bank/debts_and_paid/paid";
import DEBTS from "../component_bank/debts_and_paid/debts";
import EXPENSES_FOREING from "../components_Expenses/expenses/foreign/foreign";
import EXPENSES_NON_DEDUCTIBLE from "../components_Expenses/expenses/non_deductible/non_deductible";

import REPORT_EXPENSE from "../components_Expenses/expenses/report/report_expense";
import CONSULT_GROUP_EXPENSE from "../components_Expenses/expenses/grup_expense/consult_group_expense";
import EXPENSES_GROUP_DETAIL from "../components_Expenses/expenses/grup_expense/detail/group_details";
import CONSULT_POLICY from "../components_Expenses/policy/consult_policy";
import Consult_cut_box_sale_report from "../component_bank/cashclosing/cashclosing/report/Consult_cut_box_sale_report";
import TableRouteSalesReport from "../componentsNewDashboard/componentSalefourRoute/TableRouteSalesReport";
import Consult_annual_summary from "../componentsNewDashboard/componentSumaria/Consult_annual_summary";
import SalesReal from "../componentsNewDashboard/componentVentasTReal/SalesReal";
import ConsultEmployees from "../componentHumanResources/componentEmployees/ConsultEmployees";

import Consult_vehicle from "../componentAssets/componentVehicle/consult_vehicle";
import TableSemaphore from "../componentsNewDashboard/componentSemaphore/TableSemaphore";
import { MenuDrawer } from "../main_components/menu/PermanentMenu";
import { CssBaseline } from "@material-ui/core";
import TableFreezer from "../componentAssets/componentFreezer/TableFreezer";
import { Capacitacion } from "../componentCapacitation/Videos";
import PRODUCTS_LINE from "../components_inventory/producst_line/producst_line";
import PRODUCTS from "../components_inventory/products/products";
import MOVEMENTS from "../components_inventory/movements/movements";
import WAREHOUSE_MOVEMENTS from "../components_inventory/movements/tools/warehouse_movement";
import STOCK from "../components_inventory/stock/stock";
import ConsultSummaryWeektly from "../componentsNewDashboard/componentSumariaWeektly/ConsultSummaryWeektly";
import MovementWarehouseCabinets from "../componentAssets/componentFreezer/MovementWarehouseCabinets";
import fondo from "../img-login/curiel-wall.png";
import fc from "../img-login/fondo.png";
import PRICE_LIST from "../components_inventory/price_list/price_list";
import Set_bussines_pInvoice from "../form_business_partner/set_bussines_pInvoice.jsx";
import CONSULT_DETAIL_PRICE_LIST from "../components_inventory/price_list/detail/consult_detail_price_list";
import UPDATE_PRICE_LIST from "../components_inventory/price_list/detail/tools/update_list";
import ADD_PRODUCT from "../components_inventory/price_list/detail/tools/add_product";
import DAILY_STAFF from "../componentsNewDashboard/daily_staff/daily_staff";
import ASSIGNED_CONCEPTS from "../components_Expenses/ComponentExpenseConcept/detail/assigned_concepts";
import POINTS_OF_SALE_SECTOR from "../componentSociosNegocios/componentSectors/details/point_of_sale_sector";
import RULES_SE from "../components_Expenses/componentRulesSendingExpenses/rules_se";
import BRANDS from "../componentAssets/componentMarcaF/brands";
import WAREHOUSES from "../componentConfiguration/componentWarehouse/warehouse";
import ROUTES from "../componentConfiguration/componentRutas/routes";
import POINT_OF_SALE_WITHOUT_SECTOR from "../componentSociosNegocios/Componentpointsofsalewithoutsector/point_of_sale_without_sector";
import RECOVERY_PARTNER from "../componentSociosNegocios/componentRecoverPartner/recovery_partner";
import { ADD_SALES_BONIFICACIONES } from "../componentBonificaciones/componentVentasBonificaciones/add_sales_bonificaciones";
import BUSINESS_PARTNER_QR from "../component_businessPartner/business_partner/tools/business_partner_qr";
import SALES_DEBTS from "../component_bank/debts_and_paid/sales_debts";
import SUPPLIER from "../component_businessPartner/business_partner/supplier/supplier";
import { ViewCases } from "../componentsSupports/consultCases/Bucarcasos";
import { DASHBOARD } from "./tools/dashboard";
import COMMISSIONS from "../componentsNewDashboard/daily_staff/commissions/commissions";
import { PROFILE } from "../component_profile/profile";
import PRODUCT_LOAD from "../components_inventory/product_load/product_load";
import { PAYMENT_REPORT } from "../components_cfdi/invoice/payments_report/payment_report";
import { MAP_RELATION_INVOICE } from "../components_cfdi/invoice/map_relation/map_relation";
import WAREHOUSE_MOVEMENTS_LIST from "../components_inventory/movements/tools/warehouse_movement_list";
import { BALANCES } from "../component_bank/old_balances/balances";
import { MERCADO_STUDY } from "../study_mercado/mercado";
import CONSULT_DMS from "../component_unilever/DMS/consult_DMS";
import CONSULT_ROUTES from "../component_unilever/Interface/clients";
import PRICE_LIST_REPORT from "../components_inventory/price_list/report/price_list";
import { Hub } from "aws-amplify";
import { Auth } from "aws-amplify";
import { refresh_token_init } from "../main_components/tokens/tokenrefresh_init";
import { ASSETS_SAMSARA } from "../component_samsara/assets_samsara";
import { Autorization } from "../componentDrive/Autorization";

export class index extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // const listener = (data) => {

    //   console.log(data?.payload?.event);
    //   if(data?.payload?.event==="signOut"){
    //     sessionStorage.clear();
    //     window.location="/";
    //   }

    //   if(data?.payload?.event==="signIn"){
    //     console.log(data?.payload.data.signInUserSession.accessToken.jwtToken)

    //     console.log(data)
    //     sessionStorage.setItem("getAccesTokenRefresh",data?.payload?.data?.signInUserSession?.accessToken?.jwtToken);
    //     sessionStorage.setItem("getIdTokenRefresh",data?.payload?.data?.signInUserSession?.idToken?.jwtToken);
    //     setTimeout(async () => {
    //       await refresh_token_init();
    //     }, 300);
    //   }

    // };

    // Hub.listen("auth", listener);

    console.log(this?.props?.User?.["attributes"]);
    sessionStorage.setItem(
      "getAccesTokenRefresh",
      this?.props?.User?.["signInUserSession"]?.["accessToken"]?.["jwtToken"]
    );
    sessionStorage.setItem(
      "getIdTokenRefresh",
      this?.props?.User?.["signInUserSession"]?.["idToken"]?.["jwtToken"]
    );


    sessionStorage.setItem(
      "id_user",
      this?.props?.User?.["attributes"]?.["custom:id_user"]
    );
    sessionStorage.setItem(
      "roles_user",
      this?.props?.User?.["attributes"]?.["custom:roles"]
    );
    sessionStorage.setItem(
      "email_uset",
      this?.props?.User?.["attributes"]?.["email"]
    );
    localStorage.setItem(
      "email_user",
      this?.props?.User?.["attributes"]?.["email"]
    );

    console.log(this?.props?.User);
    var permisosUsuario = this?.props?.User?.["attributes"]?.["custom:roles"];
    var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
    var permisos = permisoUsuarioReplace?.split(",");
    console.log(permisos);
    if (this.props.AuthState === AuthState.SignedIn) {
      const roles = {
        ROLE_INVENTORY: permisos?.includes("ROLE_INVENTORY") ? " " : "none",
        ROLE_EXPENSE_RULES: permisos?.includes("ROLE_EXPENSE_RULES")
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_PROMOTIONS: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_PROMOTIONS"
        )
          ? " "
          : "none",
        ROLE_CABINET_DECAPITALIZED: permisos?.includes(
          "ROLE_CABINET_DECAPITALIZED"
        )
          ? " "
          : "none",
        ROLE_EXPENSE_EXTRANJERO: permisos?.includes("ROLE_EXPENSE_EXTRANJERO")
          ? " "
          : "none",
        ROLE_PERFECT_STORE: permisos?.includes("ROLE_PERFECT_STORE")
          ? " "
          : "none",
        ROLE_COMPLEMENTO_PAGO_SHOW_FOLIO: permisos?.includes(
          "ROLE_COMPLEMENTO_PAGO_SHOW_FOLIO"
        )
          ? " "
          : "none",
        ROLE_BUYS_PAYMENT_ACCOUNTS: permisos?.includes(
          "ROLE_BUYS_PAYMENT_ACCOUNTS"
        )
          ? " "
          : "none",
        ROLE_REPORT_NO_SALES: permisos?.includes("ROLE_REPORT_NO_SALES")
          ? " "
          : "none",
        ROLE_EXPENSE_VENDEDOR: permisos?.includes("ROLE_EXPENSE_VENDEDOR")
          ? " "
          : "none",
        ROLE_EXPENSE_DIRECTIVOS: permisos?.includes("ROLE_EXPENSE_DIRECTIVOS")
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_BUSINESS_PARTNER: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_BUSINESS_PARTNER"
        )
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_POINT_OF_SALE: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_POINT_OF_SALE"
        )
          ? " "
          : "none",
        ROLE_CABINETS: permisos?.includes("ROLE_CABINETS") ? " " : "none",
        ROLE_SALES: permisos?.includes("ROLE_SALES") ? " " : "none",
        ROLE_BUSINESS_PARTNER_SECTOR: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_SECTOR"
        )
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION"
        )
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_RECOVER: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_RECOVER"
        )
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_DISCOUNT: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_DISCOUNT"
        )
          ? " "
          : "none",
        ROLE_ASSETS: permisos?.includes("ROLE_ASSETS") ? " " : "none",
        ROLE_BUSINESS_PARTNER: permisos?.includes("ROLE_BUSINESS_PARTNER")
          ? ""
          : "none",
        ROLE_CANCEL_INVOICE: permisos?.includes("ROLE_CANCEL_INVOICE")
          ? "ROLE_CANCEL_INVOICE"
          : "DENEGADO",
        ROLE_REPORT_SALES_MANAGEMENT: permisos?.includes(
          "ROLE_REPORT_SALES_MANAGEMENT"
        )
          ? "ROLE_REPORT_SALES_MANAGEMENT"
          : "DENEGADO",
        ROLE_BANK: permisos?.includes("ROLE_BANK") ? " " : "none",
        ROLE_FINANCE: permisos?.includes("ROLE_FINANCE") ? " " : "none",
        ROLE_BUYS: permisos?.includes("ROLE_BUYS") ? " " : "none",
        ROLE_CONFIGURATION: permisos?.includes("ROLE_CONFIGURATION")
          ? " "
          : "none",
        ROLE_VEHICLES: permisos?.includes("ROLE_VEHICLES") ? " " : "none",
        ROLE_EXPENSE: permisos?.includes("ROLE_EXPENSE") ? " " : "none",
        ROLE_EXPENSE_ADMINISTRATIVOS: permisos?.includes(
          "ROLE_EXPENSE_ADMINISTRATIVOS"
        )
          ? " "
          : "none",
        ROLE_POLICY: permisos?.includes("ROLE_POLICY")
          ? "ROLE_POLICY"
          : "DENEGADO",
        ROLE_POLICY_ACCESS: permisos?.includes("ROLE_POLICY") ? " " : "none",
        PERMISOVERDETALLESGASTO: permisos?.includes(
          "ROLE_EXPENSE_ADMINISTRATIVOS"
        )
          ? "ROLE_EXPENSE_ADMINISTRATIVOS"
          : "DENEGADO",
        ROLE_INVOICING: permisos?.includes("ROLE_INVOICING")
          ? "ROLE_INVOICING"
          : "none",
        ROLE_INVOICINGACCES: permisos?.includes("ROLE_INVOICING") ? "" : "none",
        ROLE_NOMINA: permisos?.includes("ROLE_NOMINA") ? " " : "none",
        ROLE_CARTA_PORTE: permisos?.includes("ROLE_CARTA_PORTE") ? " " : "none",
        ROLE_REPORT_SALES: permisos?.includes("ROLE_REPORT_SALES")
          ? " "
          : "none",
        ROLE_COMPLEMENTO_PAGO: permisos?.includes("ROLE_COMPLEMENTO_PAGO")
          ? " "
          : "none",
        ROLE_UNILEVER: permisos?.includes("ROLE_UNILEVER") ? " " : "none",
        ROLE_ADMIN: permisos?.includes("ROLE_ADMIN") ? "" : "none",
        ROLE_EXPORT_INVOICE: permisos?.includes("ROLE_EXPORT_INVOICE")
          ? "ROLE_EXPORT_INVOICE"
          : "none",
        ROLE_ADDENDAS: permisos?.includes("ROLE_ADDENDAS") ? " " : "none",
        ROLE_HUMAN_RESOURCES: permisos?.includes("ROLE_HUMAN_RESOURCES")
          ? " "
          : "none",
        ROLE_USER: permisos?.includes("RULE_USER") ? " " : "none",
        ROLE_INVENTORY_PRODUCTS: permisos?.includes("ROLE_INVENTORY_PRODUCTS")
          ? "ROLE_INVENTORY_PRODUCTS"
          : "none",
        ROLE_INVENTORY_MOVEMENTS: permisos?.includes("ROLE_INVENTORY_MOVEMENTS")
          ? "ROLE_INVENTORY_MOVEMENTS"
          : "none",
        ROLE_INVENTORY_PRODUCTS_LINE: permisos?.includes(
          "ROLE_INVENTORY_PRODUCTS_LINE"
        )
          ? "ROLE_INVENTORY_PRODUCTS_LINE"
          : "none",
        ROLE_SUPERVISOR: permisos?.includes("ROLE_SUPERVISOR") ? "none" : "",
      };

      sessionStorage.setItem("PERMISOCANCEL", roles.ROLE_CANCEL_INVOICE);
      sessionStorage.setItem(
        "ROLE_REPORT_SALES_MANAGEMENT",
        roles.ROLE_REPORT_SALES_MANAGEMENT
      );
      sessionStorage.setItem(
        "ROLE_INVENTORY_PRODUCTS",
        roles.ROLE_INVENTORY_PRODUCTS
      );
      sessionStorage.setItem(
        "ROLE_INVENTORY_MOVEMENTS",
        roles.ROLE_INVENTORY_MOVEMENTS
      );
      sessionStorage.setItem(
        "ROLE_INVENTORY_PRODUCTS_LINE",
        roles.ROLE_INVENTORY_PRODUCTS_LINE
      );

      sessionStorage.setItem("PERMISOPOLICY", roles.ROLE_POLICY);
      sessionStorage.setItem(
        "PERMISOVERDETALLESGASTO",
        roles.PERMISOVERDETALLESGASTO
      );
      sessionStorage.setItem("ROLE_INVOICING", roles.ROLE_INVOICING);
      sessionStorage.setItem("exportPermis", roles.ROLE_EXPORT_INVOICE);
      sessionStorage.setItem("ROLE_SUPERVISOR", roles.ROLE_SUPERVISOR);

      const role_admin = "ROLE_ADMIN";
      var permisoTotal = permisos?.filter((x) => x === role_admin);
      if (permisoTotal?.length === 1) {
        sessionStorage.setItem("PERMISOCANCEL", "ROLE_CANCEL_INVOICE");
        sessionStorage.setItem("exportPermis", "ROLE_EXPORT_INVOICE");
        sessionStorage.setItem("ROLE_COMPLEMENTO_PAGO_SHOW_FOLIO", "");
      }
      var navergacion01 = roles.ROLE_BUSINESS_PARTNER === " " ? true : false;
      var navergacion02 = roles.ROLE_INVOICING !== "none" ? true : false;
      var navergacion03 = roles.ROLE_CARTA_PORTE === " " ? true : false;
      var navergacion04 = roles.ROLE_COMPLEMENTO_PAGO === " " ? true : false;

      if (navergacion01 === true) {
        var navergar01 = BookData02;
      } else {
        var navergar01 = { link: "", title: "" };
      }
      if (navergacion02 === true) {
        var navergar02 = BookData;
      } else {
        var navergar02 = { link: "", title: "" };
      }
      if (navergacion03 === true) {
        var navergar03 = BookData03;
      } else {
        var navergar03 = { link: "", title: "" };
      }

      if (navergacion04 === true) {
        var navergar04 = BookData04;
      } else {
        var navergar04 = { link: "", title: "" };
      }

      const array3 = navergar01.concat?.(navergar02, navergar03, navergar04);

      const onSideBarStatic = () => {
        const sidebar = document.getElementById("tc");
        if (sidebar && sidebar.style) {
          sidebar.style.width = "calc(100% - 220px)";
          sidebar.style.left = "200px";
          sidebar.style.right = "20px";
        }
      };

      const onSideBarNoStatic = () => {
        const sidebar = document.getElementById("tc");

        if (sidebar && sidebar.style) {
          sidebar.style.width = "calc(100% - 105px)";
          sidebar.style.left = "85px";
          sidebar.style.right = "20px";
        }
      };

      const onSideBarHide = () => {
        const sidebar = document.getElementById("tc");
        if (sidebar && sidebar.style) {
          sidebar.style.width = "100%";
          sidebar.style.left = "1%";
          sidebar.style.right = "1%";
        }
      };

      const closeSession = () => {
     
        this.props.SetAuthState(AuthState.SignedOut);
      };

      const userEmail = this?.props?.User?.["attributes"]?.["email"];
      const userId = this?.props?.User?.["attributes"]?.["custom:id_user"];
      const name = this?.props?.User?.["attributes"]?.["name"];

      localStorage.setItem("user_name", name);

      return (
        <>
          <CssBaseline />
          <MenuDrawer
            closeSession={closeSession}
            userEmail={userEmail}
            userId={userId}
            onSideBarHide={onSideBarHide}
            onSideBarStatic={onSideBarStatic}
            onSideBarNoStatic={onSideBarNoStatic}
            ROLE_ASSETS={roles.ROLE_ASSETS}
            ROLE_VEHICLES={roles.ROLE_VEHICLES}
            ROLE_ADDENDAS_MARELLI={roles.ROLE_ADDENDAS_MARELLI}
            ROLE_ADDENDAS_VOLKSWAGEN={roles.ROLE_ADDENDAS_VOLKSWAGEN}
            ROLE_ADDENDAS={roles.ROLE_ADDENDAS}
            ROLE_ADMIN={roles.ROLE_ADMIN}
            ROLE_BANK={roles.ROLE_BANK}
            ROLE_HUMAN_RESOURCES={roles.ROLE_HUMAN_RESOURCES}
            ROLE_BUYS={roles.ROLE_BUYS}
            ROLE_EXPENSE={roles.ROLE_EXPENSE}
            ROLE_EXPENSE_ADMINISTRATIVOS={roles.ROLE_EXPENSE_ADMINISTRATIVOS}
            PERMISOVERDETALLESGASTO={roles.PERMISOVERDETALLESGASTO}
            ROLE_EXPENSE_EXTRANJERO={roles.ROLE_EXPENSE_EXTRANJERO}
            ROLE_INVOICINGACCES={roles.ROLE_INVOICINGACCES}
            ROLE_SALES={roles.ROLE_SALES}
            ROLE_UNILEVER={roles.ROLE_UNILEVER}
            ROLE_INVOICING={roles.ROLE_INVOICINGACCES}
            ROLE_EXPORT_INVOICE={roles.ROLE_EXPORT_INVOICE}
            ROLE_CANCEL_INVOICE={roles.ROLE_CANCEL_INVOICE}
            ROLE_POLICY={roles.ROLE_POLICY_ACCESS}
            ROLE_CARTA_PORTE={roles.ROLE_CARTA_PORTE}
            ROLE_COMPLEMENTO_PAGO={roles.ROLE_COMPLEMENTO_PAGO}
            ROLE_USER={roles.ROLE_USER}
            ROLE_BUSINESS_PARTNER={roles.ROLE_BUSINESS_PARTNER}
            ROLE_NOMINA={roles.ROLE_NOMINA}
            ROLE_INVENTORY={roles.ROLE_INVENTORY}
            ROLE_BUSINESS_PARTNER_PROMOTIONS={
              roles.ROLE_BUSINESS_PARTNER_PROMOTIONS
            }
            ROLE_BUSINESS_PARTNER_POINT_OF_SALE={
              roles.ROLE_BUSINESS_PARTNER_POINT_OF_SALE
            }
            ROLE_CABINETS={roles.ROLE_CABINETS}
            ROLE_BUSINESS_PARTNER_SECTOR={roles.ROLE_BUSINESS_PARTNER_SECTOR}
            ROLE_BUSINESS_PARTNER_RECOVER={roles.ROLE_BUSINESS_PARTNER_RECOVER}
            ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION={
              roles.ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION
            }
            ROLE_BUSINESS_PARTNER_DISCOUNT={
              roles.ROLE_BUSINESS_PARTNER_DISCOUNT
            }
            ROLE_REPORT_SALES_MANAGEMENT={roles.ROLE_REPORT_SALES_MANAGEMENT}
            ROLE_FINANCE={roles.ROLE_FINANCE}
            ROLE_CONFIGURATION={roles.ROLE_CONFIGURATION}
            ROLE_REPORT_SALES={roles.ROLE_REPORT_SALES}
            ROLES={roles}
            array3={array3}
          />
          <div id="tc" className="tc">
            {this.props.User === null ? (
              <div> Loading User </div>
            ) : (
              <div className="tc w-100">
                <Router>
                  <Switch>
                    <Route
                      path="/registroManualBase"
                      exact
                      component={Set_bussines_pInvoice}
                    />
                    <Route
                      path="/redireccion"
                      exact
                      component={Get_bussinesP}
                    />

                    <Route path="/consultaCFDI" exact component={CfdiTable} />
                    <Route
                      path="/GenerarFactura"
                      exact
                      component={GENERATE_INVOICE}
                    />
                    <Route
                      path="/cartaporte4.0"
                      exact
                      component={SetCarataportev4}
                    />
                    <Route path="/pago" exact component={PAGO} />
                    <Route path="/updateBP" exact component={UpdateBusnes} />
                    <Route path="/validador" exact component={SetValidador} />
                    <Route
                      path="/FacturasPPD"
                      exact
                      component={SetfacturaPPD}
                    />
                    <Route
                      path="/consultaFacturasProvedores"
                      exact
                      component={SetConsultarFacturasProveedores}
                    />
                    <Route
                      path="/consult_report_bonuses"
                      exact
                      component={SetTableBonuses}
                    />
                    <Route
                      path="/edit_add_bonuses"
                      exact
                      component={SetEditAddBonuses}
                    />
                    <Route
                      path="/consult_taxes"
                      exact
                      component={SetConsultaTaxes}
                    />
                    <Route
                      path="/consult_configure_taxes"
                      exact
                      component={SetConsulConfigureTaxes}
                    />
                    <Route
                      path="/consult_concept"
                      exact
                      component={CONSUL_CONCEPT}
                    />
                    <Route path="/rutas" exact component={SetReportRoutes} />
                    <Route
                      path="/NewDashboard"
                      exact
                      component={NewDashboard}
                    />
                    <Route
                      path="/consult_sectors"
                      exact
                      component={SetSectors}
                    />
                    <Route
                      path="/consult_point_sale_sector"
                      exact
                      component={POINTS_OF_SALE_SECTOR}
                    />
                    <Route
                      path={"/consult_discounts"}
                      exact
                      component={SetDiscounts}
                    ></Route>
                    <Route
                      path={"/consult_promotions"}
                      exact
                      component={SetPromotions}
                    ></Route>
                    <Route
                      path={"/consult_point_of_sale"}
                      exact
                      component={setPointsOfSale}
                    ></Route>
                    <Route
                      path={"/consult_point_of_sale_without_sector"}
                      exact
                      component={POINT_OF_SALE_WITHOUT_SECTOR}
                    ></Route>
                    <Route
                      path="/consult_recover_partner"
                      exact
                      component={RECOVERY_PARTNER}
                    ></Route>
                    <Route
                      path="/register_general"
                      exact
                      component={SetRegisterGeneral}
                    ></Route>
                    <Route
                      path="/cashclosing"
                      exact
                      component={SetBanc}
                    ></Route>
                    <Route
                      path="/Consult_cut_box_sale"
                      exact
                      component={Consult_cut_box_sale}
                    ></Route>
                    <Route
                      path={"/consult_accounting_account"}
                      exact
                      component={TableAccountingAccount}
                    ></Route>
                    <Route
                      path={"/consult_rules_sending_expenses"}
                      exact
                      component={RULES_SE}
                    ></Route>
                    <Route
                      path={"/consult_expense_concept"}
                      exact
                      component={TableExpenseConcept}
                    ></Route>
                    <Route
                      path={"/consult_roles"}
                      exact
                      component={TableRoles}
                    ></Route>

                    <Route
                      path={"/edit_company"}
                      exact
                      component={EditCompany}
                    ></Route>
                    <Route
                      path={"/consult_users"}
                      exact
                      component={TableUsers}
                    ></Route>
                    <Route
                      path={"/consult_driver"}
                      exact
                      component={Consult_drivers}
                    ></Route>
                    <Route
                      path="/expenses"
                      exact
                      component={EXPENSES_NON_DEDUCTIBLE}
                    />
                    <Route
                      path="/expensesExtranjero"
                      exact
                      component={EXPENSES_FOREING}
                    />
                    <Route
                      path="/expensesReport"
                      exact
                      component={REPORT_EXPENSE}
                    />
                    <Route
                      path="/expensesGrup"
                      exact
                      component={CONSULT_GROUP_EXPENSE}
                    />
                    <Route
                      path="/expensesGrupDetail"
                      exact
                      component={EXPENSES_GROUP_DETAIL}
                    />
                    <Route path="/policy" exact component={CONSULT_POLICY} />
                    <Route
                      path="/cut_box_report"
                      exact
                      component={Consult_cut_box_sale_report}
                    />
                    <Route
                      path={"/consult_route_sales_report"}
                      exact
                      component={TableRouteSalesReport}
                    ></Route>
                    <Route
                      path={"/consult_report_sumary"}
                      exact
                      component={Consult_annual_summary}
                    ></Route>
                    <Route
                      path={"/consult_sumary_weektly"}
                      exact
                      component={ConsultSummaryWeektly}
                    ></Route>

                    <Route
                      path="/Consult_route"
                      exact
                      component={ROUTES}
                    ></Route>
                    <Route
                      path="/Consult_report_sale_real"
                      exact
                      component={SalesReal}
                    ></Route>
                    <Route
                      path="/consult_employees"
                      exact
                      component={ConsultEmployees}
                    ></Route>
                    <Route
                      path={"/consult_assigned_concepts"}
                      exact
                      component={ASSIGNED_CONCEPTS}
                    ></Route>
                    <Route
                      path={"/consult_brands"}
                      exact
                      component={BRANDS}
                    ></Route>
                    <Route
                      path={"/TableWarehouse"}
                      exact
                      component={WAREHOUSES}
                    ></Route>
                    <Route
                      path={"/consult_vehicle"}
                      exact
                      component={Consult_vehicle}
                    ></Route>
                    <Route
                      path={"/consult_semaphore"}
                      exact
                      component={TableSemaphore}
                    ></Route>
                    <Route
                      path={"/consult_frezzers"}
                      exact
                      component={TableFreezer}
                    ></Route>
                    <Route
                      path={"/consult_warehouse_movement_cabinets"}
                      exact
                      component={MovementWarehouseCabinets}
                    ></Route>
                    <Route
                      path={"/consult_capacitation"}
                      exact
                      component={Capacitacion}
                    ></Route>
                    <Route
                      path={"/products_line"}
                      exact
                      component={PRODUCTS_LINE}
                    ></Route>
                    <Route
                      path={"/products"}
                      exact
                      component={PRODUCTS}
                    ></Route>
                    <Route
                      path={"/movements"}
                      exact
                      component={MOVEMENTS}
                    ></Route>
                    <Route
                      path={"/werehouse_movements"}
                      exact
                      component={WAREHOUSE_MOVEMENTS}
                    ></Route>
                    <Route path={"/stock"} exact component={STOCK}></Route>
                    <Route
                      path={"/price_list"}
                      exact
                      component={PRICE_LIST}
                    ></Route>
                    <Route
                      path={"/price_list_products"}
                      exact
                      component={CONSULT_DETAIL_PRICE_LIST}
                    ></Route>
                    <Route
                      update_price_list
                      path={"/update_price_list"}
                      exact
                      component={UPDATE_PRICE_LIST}
                    ></Route>
                    <Route
                      update_price_list
                      path={"/add_product"}
                      exact
                      component={ADD_PRODUCT}
                    ></Route>
                    <Route
                      update_price_list
                      path={"/daily_staff"}
                      exact
                      component={DAILY_STAFF}
                    ></Route>
                    <Route
                      path={"/add_sales_bonificaciones"}
                      exact
                      component={ADD_SALES_BONIFICACIONES}
                    ></Route>
                    <Route
                      update_price_list
                      path={"/DEBTS"}
                      exact
                      component={DEBTS}
                    ></Route>
                    <Route
                      update_price_list
                      path={"/PAID"}
                      exact
                      component={PAID}
                    ></Route>

                    <Route
                      update_price_list
                      path={"/supplier"}
                      exact
                      component={SUPPLIER}
                    ></Route>
                    <Route
                      update_price_list
                      path={"/createQr"}
                      exact
                      component={BUSINESS_PARTNER_QR}
                    ></Route>

                    <Route
                      path={"/help_center"}
                      exact
                      component={ViewCases}
                    ></Route>
                    <Route path={"/"} exact component={DASHBOARD}></Route>
                    <Route
                      path={"/commissions"}
                      exact
                      component={COMMISSIONS}
                    ></Route>
                    <Route path={"/profile"} exact component={PROFILE}></Route>
                    <Route
                      path={"/product_load"}
                      exact
                      component={PRODUCT_LOAD}
                    ></Route>
                    <Route
                      path={"/consult_payment_report"}
                      exact
                      component={PAYMENT_REPORT}
                    ></Route>
                    <Route
                      path={"/map_relation"}
                      exact
                      component={MAP_RELATION_INVOICE}
                    ></Route>

                    <Route
                      path={"/warehouse_movements_list"}
                      exact
                      component={WAREHOUSE_MOVEMENTS_LIST}
                    ></Route>
                    <Route
                      path={"/old_balances"}
                      exact
                      component={BALANCES}
                    ></Route>
                    <Route
                      path={"/products_date"}
                      exact
                      component={MERCADO_STUDY}
                    ></Route>
                    <Route
                      path={"/consult_DMS"}
                      exact
                      component={CONSULT_DMS}
                    ></Route>

                    <Route
                      path={"/PRICE_LIST_REPORT"}
                      exact
                      component={PRICE_LIST_REPORT}
                    ></Route>

                    <Route
                      path={"/SAMSARA_ENERGY"}
                      exact
                      component={ASSETS_SAMSARA}
                    ></Route>
                    <Route
                      path={"/drive_to_drive"}
                      exact
                      component={Autorization}
                    >
                    </Route>

                  </Switch>

                  {/* <HelpVideo></HelpVideo> */}
                  <h4 className="version">v13.1.8</h4>
                </Router>
              </div>
            )}
          </div>
        </>
      );
    } else {
      return (
        <div className="container_login">
          <div className="grid-login">
            <div className="formsesion">
              <div className="logoempresa-ingresar">
                <img src={fc} alt=""></img>
              </div>

              <div className="camplogin">
                <br></br>
                <div className="div-lg1">
                  <p className="bienvenido">Bienvenido de nuevo</p>
                  <p className="text-login">Operación Dinámica V.2</p>
                </div>
                <br></br>
                <br></br>

                <div
                  className="boton-ingresar"
                  onClick={(e) => this.props.SetAuthState(AuthState.SignIn)}
                >
                  <a>Ingresar</a>
                </div>
                {/* <img
                src={fcgif}
              ></img>              */}
              </div>
            </div>
            <div className="imglogin">
              <img src={fondo} alt=""></img>
            </div>
          </div>
        </div>

        // <div className="colorfondo">
        //   <div className="parent clearfix">
        //     <div className="bg-illustration"></div>

        //     <div className="login">
        //       <div className="container">
        //         <br></br>
        //         <h2 className="titleOD2"> Operación Dinámica V.2</h2>

        //         <img className="avatar" src={BG} alt="logo" />

        //         <div className="login-form">
        //         <input
        //             type="button"
        //             className="form-control btn btn-primary"
        //             value="Ingresar"
        //             onClick={() => this.props.SetAuthState(AuthState.SignIn)}
        //           />
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      );
    }
  }
}

export default index;
