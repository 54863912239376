import React, { useEffect, useState } from 'react'
import "./buscarcasos.css";
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';

export const IMGDATA = (props) => {

    const images = props.content; // Arreglo de imágenes en base64
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const handlePrevClick = () => {
        setCurrentImageIndex((prevIndex) => prevIndex - 1);
      };
    
      const handleNextClick = () => {
        setCurrentImageIndex((prevIndex) => prevIndex + 1);
      };
    
      const canGoPrev = currentImageIndex > 0;
      const canGoNext = currentImageIndex < images.length - 1;
    
      
  // Evento de teclado para la cruceta (flechas izquierda y derecha)
  const handleKeyDown = (event) => {
    if (event.keyCode === 37 && canGoPrev) {
     
      handlePrevClick();
    } else if (event.keyCode === 39 && canGoNext) {
      handleNextClick();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentImageIndex, canGoPrev, canGoNext]);


    return(
        <>
        <div className="carousel-container div-modal-i5m5">
            {canGoPrev && <div className="back-img"><SkipPreviousIcon  onClick={handlePrevClick}/></div>}
            <div className='img-conteiner-ticket'>
                <div className='img-ticket'>
                    <img src={`data:image/png;base64,${images[currentImageIndex]}`}></img>
                </div>
            </div>
            {canGoNext && <div className="next-img"><SkipNextIcon  onClick={handleNextClick}/></div>}
        </div>
        </>
    )
}