//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import LoadingScreen from "react-loading-screen";
import { StickyTable, Row, Cell } from "react-sticky-table";

//COMPONENTES
import TableContainer from "../main_components/Table/TableContainer";
import Motivo from "../imgComponents/motivo.png";
import {
  SelectColumnFilter,
  ColumnFilter,
} from "./filters";


//ESTILOS

//IMAGENES
import Spinner from "../imgComponents/S.gif";

const Relacionar = () => {
  const dialog = useDialog();

  const [data, setData] = useState([]);
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isLoding, setIsLoding] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoding(true);


    axios
      .get("https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/invoices", {
        params: {
          di: fi || "",
          df: ff || "",
          b_partner: sessionStorage.getItem("NameBusinespartner"),
          b_partner: sdn || "",
          cancel: cancel || false,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;

        setData(data);
        setTimeout(() => {
          setIsLoding(false);
          setff("");
          setfi("");
          setRFC("");
          setSDN("");
        }, 1000);
      })
      .catch((err) => {
        console.log("error", err);
        setData([]);

        setTimeout(() => {
          setIsLoding(false);
        }, 1000);
      });
  };

  useEffect(() => {
    const get_invoice = () => {
      axios
        .get(
          "https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/invoices",
          {
            params: {
              di: "",
              df: "",
              rfc: "",
              type: "",
              b_partner: sessionStorage.getItem("NameBusinespartner"),
            },
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const data = response.data;

          setData(data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    get_invoice();
    setTimeout(() => {
      setIsLoding(false);
    }, 1000);
  }, [isLoading]);

  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function setRelacion() {
            sessionStorage.setItem(
              "relacionFacturauuid",
              row.original.uuid_sat
            );
            sessionStorage.setItem("relacionFacturAid", row.original.folio);
          }
          return (
            <di className="iconCenter">
              <img
              alt=""
                src={Motivo}
                className=" cancel"
                onClick={async () => {
                  const result = await Confirm(
                    "Factura relacionada: " + row.original.uuid_sat,
                    "ESTA USTED SEGURO DE RELACIONAR ESTA FACTURA, DE NO SER ASI DE AL BOTON DE CANCELAR PARA ESCOGER OTRA"
                  );

                  if (result) {
                    // Сonfirmation confirmed
                    setRelacion(result);
                  } else {
                    // Сonfirmation not confirmed
                  }
                }}
              ></img>
            </di>
          );
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Serie",
        accessor: "serie",
        Filter: SelectColumnFilter,
        filter: "equals",
        id: "seriee",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Folio",
        accessor: "folio",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Filter: ColumnFilter,
        Header: "Uuid",
        accessor: "uuid_sat",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
      },
    ],<div className="containerCosultCancel">
    <div className="bp-bus1">
      <input
        type="text"
        className="fi"
        onFocus={(e) => {
          e.currentTarget.type = "date";
          e.currentTarget.focus();
        }}
        placeholder="Fecha inicial"
        onChange={(event) => setfi(event.target.value)}
      ></input>
    </div>
    <div className="bp-bus2">
      <input
        type="text"
        onFocus={(e) => {
          e.currentTarget.type = "date";
          e.currentTarget.focus();
        }}
        placeholder="Fecha Final"
        onChange={(event) => setff(event.target.value)}
      ></input>
    </div>
    <div className="bp-bus">
      <button className="button" onClick={handleSubmit} type="button">
        Buscar
      </button>
    </div>
  </div>
    
  );

  return (
    <>
      <div ></div>
      <div
        style={{
          width: "100%",
          height: "94%",
          paddingLeft: "20px",
          paddingLeft: "20px",
        }}
      >
        {isLoding ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text="Buscando..."
          />
        ) : (
          <div style={{ width: "90%", height: "90%", paddingLeft: "200px" }}>
            <StickyTable>
        


              <div className="containerCosultCancel">
          <div className="bp-bus1">
            <input
              type="text"
              className="fi"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha inicial"
              onChange={(event) => setfi(event.target.value)}
            ></input>
          </div>
          <div className="bp-bus2">
            <input
              type="text"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha Final"
              onChange={(event) => setff(event.target.value)}
            ></input>
          </div>
          <div className="bp-bus">
            <button className="button" onClick={handleSubmit} type="button">
              Buscar
            </button>
          </div>
        </div>

              <TableContainer
                id="dos"
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
              />
            </StickyTable>
          </div>
        )}
      </div>
    </>
  );
};

export default Relacionar;
