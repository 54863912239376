//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef} from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import { StickyTable } from "react-sticky-table";

//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import { get_products } from "../../services/inventory/inventory";
import CREATE_UPDATE from "./tools/create_update";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import SubTableContainer from "../../main_components/Table/SubTableContainer";
import { delete_products } from "../../services/inventory/inventory";
import { recovery_products } from "../../services/inventory/inventory";
import { SelectColumnFilter } from "../../components_cfdi/filters";

import FormControlLabel from "@mui/material/FormControlLabel";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/reconsultaTable.png";

import TAX from "./tools/tax";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import "./products.css";
import { COLUMS_SHOW } from "../../main_components/Table/ColumsShow";
import {styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { GENERATE_COLUMNS, SHOWDATA } from "../../main_components/Table/generate_columns";
import { MaterialUISwitch } from "../../main_components/material_switch/switch";
const PRODUCTS = (props) => {

  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const showcolumns = GENERATE_COLUMNS("productos", 17);
  const [data, setData] = useState([]);
  const [modalT, setmodalT] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [deleted, setdeleted] = useState(false);
  const notificationSystemRef = useRef();

  const handleSubmit = async (a,b) => {
    setmodalT(true);
    setMenssage("Productos...");
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            if(props.subtabla===true){
              console.log("entra dato")
              const dt = await get_products(sessionStorage.getItem("id_product_line"),deleted);
              setData(dt.data);
              setmodalT(false);
              if(a){
                handle_alert(b,a)
              }
            }else{
              const dt = await get_products(null,deleted);
              setData(dt.data);
              setmodalT(false);
              if(a){
                handle_alert(b,a)
              }
            }
           
          } catch (err) {
            setData([]);
            setMenssage("");
            setmodalT(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };
  const handle_alert = (msj, status) => {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else{
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };
  const handle_delete_product= async(id)=>{
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            await delete_products(id)
            handle_reconsult(true,"Producto eliminado correctamente")
          } catch (err) {
            handle_alert(JSON.stringify(err?.response?.data),false)
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  }
  const handle_reconsult=(a,b)=>{
    if(a){
      handleSubmit(a,b);
    }else if(a===false){
      handle_alert(b,a);
    }
  }
  const handle_recovery = async(id)=>{
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            await recovery_products(id)
            handleSubmit();
            handle_reconsult(true,"Producto recuperado correctamente")
          } catch (err) {
            handle_alert(JSON.stringify(err?.response?.data),false)
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  }
  useEffect(() => {
    handleSubmit();
  }, [deleted]);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <img
              alt=""
              title="Crear producto"
              src={CREATE}
              style={{display:sessionStorage.getItem("ROLE_INVENTORY_PRODUCTS")==="ROLE_INVENTORY_PRODUCTS"?"":"none"}}
              className="AddDatapointer"
              onClick={async () => {
                const result = await CustomDialog(
                  <CREATE_UPDATE
                    subtabla={props.subtabla}
                    name_line={props.subtabla===true?sessionStorage.getItem("id_product_line"):4}
                    code={""}
                    description={""}
                    enable={true}
                    method={"CREATE"}
                    name_supplier={null}
                    id_supp={null}
                    product_name={""}
                    barcode={""}
                    type={"CONGELADOS"}
                    product_line_data={"CLIENTE"}
                    only_promo={false}
                    cost={1}
                    pieces={1}
                    clave_prod_serv={""}
                    clave_unidad={""}
                    clave_unidad_2={""}
                    weight={0.1}
                    iva0={true}
                    iva16={false}
                    ieps8={false}
                    minimum_sales_unit={"PIEZA"}
                    minimum_sales_unit2={"PIEZA"}
                    unit={"LITROS"}
                    unit_dms={"ZCU"}
                    unit_conversion={0}
                    brand={"MORDISKO"}
                  ></CREATE_UPDATE>,
                  {
                    className: "modalProduct",
                    title: "Crear producto",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
                // eslint-disable-next-line no-lone-blocks
                {handle_reconsult(result?.validate,result?.msj)}
              }}
            ></img>
          );
        },
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("id_product_tax", row.original.id);
          }

          return (
            <div>
              <a
                title="Configuración de impuestos"
                {...row.getToggleRowExpandedProps()}
              >
                {row?.isExpanded ? (
                  <span title="Configuración del impuesto">🔼</span>
                ) : (
                  <span
                    title="Configuración del impuesto"
                    onClick={guardardato}
                  >
                    🔽
                  </span>
                )}
              </a>
              <br/>
              <img
                alt=""
                style={{display:sessionStorage.getItem("ROLE_INVENTORY_PRODUCTS")==="ROLE_INVENTORY_PRODUCTS"?row.original.deleted_logical===true?"":"none":"none"}}
                title="Recuperar producto"
                src={VER}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de recuperar este producto?",
                    "Recuperar Producto",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_recovery(row.original.id)
                  } else {
                  }
                }}
              ></img>
              <br/>
              <img
                alt=""
                title="Actualizar producto"
                src={SAVE}
                style={{display:sessionStorage.getItem("ROLE_INVENTORY_PRODUCTS")==="ROLE_INVENTORY_PRODUCTS"?"":"none"}}
                onClick={async () => {
                  
                  const result = await CustomDialog(
                    
                    <CREATE_UPDATE
                      subtabla={props.subtabla}
                      id={row.original.id}
                      name_line={row.original.id_products_line_fk}
                      code={row.original.code}
                      description={row.original.description}
                      enable={row.original.enable}
                      method={"UPDATE"}
                      name_supplier={row.original.name_supplier}
                      id_supp={row.original.id_supp}
                      product_name={row.original.product_name}
                      barcode={row.original.barcode}
                      type={row.original.type}
                      product_line_data={row.original.product_line_data}
                      only_promo={row.original.only_promo}
                      cost={row.original.cost}
                      pieces={row.original.pieces}
                      clave_prod_serv={row.original.clave_prod_serv}
                      clave_unidad={row.original.clave_unidad}
                      clave_unidad_2={row.original.clave_unidad}
                      weight={row.original.weight}
                      iva0={row.original.iva0}
                      iva16={row.original.iva16}
                      ieps8={row.original.ieps8}
                      minimum_sales_unit={row.original.minimum_sales_unit}
                      minimum_sales_unit2={row.original.minimum_sales_unit_2}
                      unit={row.original.unit}
                      unit_dms={row.original.min_sales_unit_dms}
                      unit_conversion={row.original.unit_conversion}
                      brand={row.original.brand}
                    ></CREATE_UPDATE>,
                    {
                      className: "modalTItle",
                      title: "Actualizar producto",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {handle_reconsult(result?.validate,result?.msj)}
                }}
                className="centerText, taxes"
              ></img>

              <img
                alt=""
                title="Eliminar producto"
                src={DELETE}
                style={{display:sessionStorage.getItem("ROLE_INVENTORY_PRODUCTS")==="ROLE_INVENTORY_PRODUCTS"?"":"none"}}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este producto?",
                    "Eliminar Producto",
                    "Si",
                    "No"
                  );
                  if (result) {
                    if(row.original.enable === false){handle_delete_product(row.original.id)}
                    else{handle_alert("El producto debe estar marcado como no disponible", false)}
                    } else {}
                }}
              ></img>
            </div>
          );
        },
        maxWidth: 50,
        minWidth: 50,
        width: 50,
        className: "center_data"
      },

      {
        Header: "Código",
        accessor: "code",
        show: showcolumns.productos_c1.value,
        id:"code",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Nombre del producto",
        accessor: "product_name",
        whiteSpace:"normal",
        show: showcolumns.productos_c2.value,
        id: "product_name",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "Descripción",
        accessor: "description",
        show: showcolumns.productos_c3.value,
        id: "description",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "Código de barras",
        accessor: "barcode",
        show: showcolumns.productos_c4.value,
        id: "barcode",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Tipo",
        accessor: "type",
        Filter: SelectColumnFilter,
        filter:"equals",
        show: showcolumns.productos_c5.value,
        id: "type",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Unidad",
        accessor: "unit",
        Filter: SelectColumnFilter,
        filter:"equals",
        show: showcolumns.productos_c6.value,
        id: "unit",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Conversión",
        accessor: "unit_conversion",
        show: showcolumns.productos_c7.value,
        id: "unit_conversion",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Minima",
        accessor: "minimum_sales_unit",
        Filter: SelectColumnFilter,
        filter:"equals",
        show: showcolumns.productos_c8.value,
        id:"minimum_sales",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Unidad SAT",
        accessor: "clave_unidad",
        show: showcolumns.productos_c9.value,
        id: "clave_unidad",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Piezas",
        accessor: "pieces",
        show: showcolumns.productos_c10.value,
        id: "pieces",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Peso",
        accessor:"weight",
        show: showcolumns.productos_c11.value,
        id: "weight",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Clasificación",
        accessor: "brand",
        Filter: SelectColumnFilter,
        filter:"equals",
        show: showcolumns.productos_c12.value,
        id: "brand",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Activo",
        Filter: SelectColumnFilter,
        filter:"equals",
        accessor: (d) => {
          return d.enable === true ? "Si" : "NO";
        },
        show: showcolumns.productos_c13.value,
        id: "enable",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Clave SAT8",
        accessor: "clave_prod_serv",
        show: showcolumns.productos_c14.value,
        id: "clave_prod_serv",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },
      {
        Header: "Clave DMS",
        accessor: "min_sales_unit_dms",
        Filter: SelectColumnFilter,
        filter:"equals",
        show: showcolumns.productos_c15.value,
        id: "min_sales_unit_dms",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
        
      },   
      {
        Header: "Costo",
        show: showcolumns.productos_c16.value,
        id: "cost",
        accessor: (d) => {
          return d.cost
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.cost)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.cost);
        },
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "right_data"
      },  
      
      {
        Header: "Fecha de Registro",
        accessor: "date_register",
        show: showcolumns.productos_c17.value,
        id: "date_register",
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        className: "center_data"
      },   
     
      
    ],
    [
      showcolumns
    ]
  );
  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };
  


  return (
    <div >
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
      <BREADCRUMBS niveles={
            [
              {label: "CONSULTAR PRODUCTOS", path: null},
            ]
          }
      ></BREADCRUMBS>
        <COLUMS_SHOW
            filtersColumsView={filtersColumsView}
            datacolums={SHOWDATA(columns, showcolumns, "productos")}
         />
      <div className="selectProduct" style={{display:props.subtabla===true?"none":""}}>
        <select name="" id="" onChange={(e)=>setdeleted(e.target.value)}>
          <option value={false}>Vigentes</option>
          <option value={true}>Eliminados</option>
        </select>
      </div>
    
          
          <div className="containerCutBox">
                <div className="expen-8 color-1">
                  <FormControlLabel
                    control={
                      <MaterialUISwitch
                        sx={{ m: 1 }}
                        onChange={handlechecked}
                        checked={filtersColumsView}
                      />
                    }
                    label={
                      filtersColumsView === true
                        ? "Activar Filtros"
                        : "Desactivar Filtros"
                    }
                  />
                </div>
            </div>

        <div className="TableProduct">
          <StickyTable>
          <TableContainer
            GrantotalCabeceras={["Nombre del producto","Tipo","Minima","Clasificació","Peso"]}
            columns={columns}
            nametable={"Tabla Productos"}
            sinval={true}
            data={data}
            exportar={true}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <TAX />
                </div>
              );
            }}
          />
          </StickyTable>
        </div>
    </div>
  );
};

export default PRODUCTS;
